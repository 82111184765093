import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { apiManager } from "../../../data/apiHandler";
import swal from "sweetalert";
import { Icon } from "@iconify/react/dist/iconify.js";
import BoostrapModel from "../../Tools/BoostrapModel";
import EditMakeModal from "./editMakeModal";
import Button from "../../Tools/Button";

function ViewMake() {
  //hooks
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const ID = params?.get("id");
  //states
  const [pageLoading, setPageLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [make, setMake] = useState(null);
  const [models, setModels] = useState([]);
  const getData = async (id) => {
    setPageLoading(true);
    const apiCalls = await Promise.all([
      apiManager("GET", `general/makes?lang=en&name=${id}`),
      apiManager("GET", `opp/getModelNames?lang=en&makeId=${id}`),
    ]).catch((err) => {
      swal("Error", err?.message, "error");
      console.log(err);
      setPageLoading(false);
    });
    const res1 = apiCalls[0];
    const res2 = apiCalls[1];

    if (res1?.status) {
      setMake(res1?.data?.makes?.[0]);
    } else {
      swal("Error", res1?.message, "error");
      console.log(res1);
    }

    if (res2?.status) {
      setModels(res2?.data);
    } else {
      // swal("Error", res2?.message, "error");
      setModels([]);
      console.log(res2);
    }

    setPageLoading(false);
  };
  //toogle modal states
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const openModal = (data) => {
    setModalData(data);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setTimeout(() => {
      setModalData(null);
    }, 300);
  };

  useEffect(() => {
    if (ID) {
      getData(ID);
    }
  }, []);

  const handleSubmit = async (data) => {
    setFormLoading(true);
    if (modalData?.type === "edit") {
      apiManager("PUT", `general/make?lang=en&id=${make?._id}`, data)
        .then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
            getData(ID);
            closeModal();
          } else {
            swal("Error", res?.message, "error");
            getData(ID);
          }
          setFormLoading(false);
        })
        .catch((err) => {
          console.log(err);
          swal("Error", err?.message, "error");
          getData(ID);
          setFormLoading(false);
        });
    } else if (modalData?.type === "delete") {
      apiManager("DELETE", `general/make?lang=en&id=${make?._id}`)
        .then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
            getData();
            closeModal();
            navigate(-1);
          } else {
            swal("Error", res?.message, "error");
            getData();
          }
          setFormLoading(false);
        })
        .catch((err) => {
          console.log(err);
          swal("Error", err?.message, "error");
          getData();
          setFormLoading(false);
        });
    } else if (modalData?.type === "add-model") {
      const raw = JSON.stringify({
        data: {
          model_make_id: ID,
          model_name: data?.modelName,
        },
      });
      let config = {
        method: "POST",
        headers: {
          authorization: process.env.REACT_APP_STRAPI_AUTH_TOKEN,
          "Content-Type": "application/json",
        },
        body: raw,
      };
      
      const apiCall = () => {
        return fetch(
          `${process.env.REACT_APP_STRAPI_BASE_URL}carq-datasets`,
          config
        );
      };
      apiCall().then((pr) => {
        pr.json()
          .then((res) => {
            console.log(res);
            if (res?.data) {
              swal("Success", "Model Added", "success");
              closeModal();
              getData(ID);
            } else {
              swal("Error", "Something went wrong!", "error");
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
            swal("Error", "Something went wrong!", "error");
          });
      });

      setFormLoading(false);
    }
  };

  return pageLoading ? (
    <div
      class="d-flex justify-content-center"
      style={{ minHeight: "100vh", marginTop: "25vh" }}
    >
      <div class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      <div class="container">
        <h6 class="back d-flex justify-content-between">
          <a onClick={() => navigate(-1)}>
            <img src="../assets/images/backbt.png" /> {"Go back"}
          </a>
        </h6>

        <div className="row">
          <div className="col-lg-12 mb-3 d-flex justify-content-center position-relative rounded-4 shadow-sm">
            <div
              className="fs-3 mb-4 position-absolute "
              style={{ left: "15px", top: "15px" }}
            >
              {make?.name}
            </div>
            <img
              src={make?.image}
              className="mb-4 mt-5"
              height={300}
              width={300}
            />
            <Icon
              role="button"
              icon="bxs:message-square-edit"
              fontSize={34}
              className="position-absolute"
              style={{ color: "#437ee8", right: "15px", top: "15px" }}
              onClick={(e) =>
                openModal({ type: "edit", title: "Edit Make", item: make })
              }
            />
            <Icon
              role="button"
              icon="material-symbols:delete-sharp"
              fontSize={36}
              className="position-absolute"
              style={{ color: "#FF6B6B", right: "15px", bottom: "15px" }}
              onClick={(e) =>
                openModal({ type: "delete", title: "Delete Make", item: make })
              }
            />
          </div>
          <div className="col-lg-12">
            <div className="d-flex justify-content-between">
              <h4 className="mb-3">Models</h4>
              <div
                className="btn btn-primary d-flex justify-content-center align-items-center"
                style={{
                  marginLeft: "0px",
                  textDecoration: "none",
                  backgroundColor: "#4c7ee8",
                }}
                onClick={() =>
                  openModal({ type: "add-model", title: "Add Model" })
                }
              >
                Add Models
              </div>
            </div>
            <div className="col-lg-6">
              {models && models?.length > 0 ? (
                models.map((item) => {
                  return <h5>{item}</h5>;
                })
              ) : (
                <>
                  <h5>No models were found</h5>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <BoostrapModel
        show={modal}
        heading={modalData?.title}
        style={"warning warningKyc"}
        fullWidth
        inlineStyle={{
          paddingRight: "0px !important",
        }}
        component={
          <EditMakeModal
            submitCallback={handleSubmit}
            data={modalData}
            loading={formLoading}
          />
        }
        onHide={() => setModal(false)}
      />
    </>
  );
}

export default ViewMake;

import ChecboxWithSpan from "../../Tools/CheckboxWithSpan";
const UsersOption = ({ allFilter, setAllFilter }) => {
  const options = [
    { label: "All users", value: 1 },
    { label: "All dealers", value: 2 },
    { label: "All private users", value: 3 },
  ];
  return (
    <ul>
      {/* <li>
        {" "}
        <ChecboxWithSpan
          parentStyle={"checkcontainer"}
          childStyle={"radiobtn"}
          inType={"checkbox"}
          inputStyle={"quality"}
          name={"business_users"}
          value={"T1"}
        />{" "}
        All business users
      </li>
      <li>
        <ChecboxWithSpan
          parentStyle={"checkcontainer"}
          childStyle={"radiobtn"}
          inType={"checkbox"}
          inputStyle={"quality"}
          name={"regular_users"}
          value={"T1"}
        />{" "}
        All regular users
      </li> */}
      {options.map((item, i) => {
        return (
          <li key={i} style={{ padding: "0px 15px 0px" }}>
            <label
              htmlFor={`sendToInput${i}`}
              style={{ fontSize: "15px" }}
              className="ms-2 d-flex align-items-center"
            >
              <input
                id={`sendToInput${i}`}
                disabled={allFilter?.users.length > 0}
                type="radio"
                checked={allFilter.sendTo == item?.value}
                value={item?.value}
                className="me-2"
                onClick={(e) =>
                  // console.log(e.target.value)
                  setAllFilter({ ...allFilter, sendTo: e.target.value })
                }
              />
              <span>{item?.label}</span>
            </label>
          </li>
        );
      })}
    </ul>
  );
};
export default UsersOption;

const ChecboxWithSpan = ({
  inStyle,
  parentStyle,
  childStyle,
  name,
  inType,
  inputStyle,
  value,
  title,
  checked,
  onChange,
  disabled,
  id
}) => {
  return (
    <span style={inStyle} className="d-flex">
      <span class={parentStyle}>
        <input
          id={id}
          type={inType}
          disabled={disabled}
          name={name}
          class={inputStyle}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span class={childStyle}></span>
      </span>{" "}
      <label htmlFor={id}>{title}</label>
    </span>
  );
};
export default ChecboxWithSpan;

import React, { Component, useEffect, useState } from "react";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import BasicTable from "../../Tools/Table";
import TopbarWithDateandSearch from "../../Tools/TopbarWithDateandSearch";
import { userAccountList } from "../../../Reducer/userAccount/userAccountSlice";
import {
  dealerAccountColumns,
  reasonsToDelist,
  userAccountColumns,
} from "../../../data/data";
import ReasonModel from "../../Tools/ReasonModel";
import BoostrapModel from "../../Tools/BoostrapModel";
import { deleteOption, issueOption, refundOption } from "../../../data/data";
import { reportFun, sendEmail, updateProfile } from "../../../services/api";
import { set } from "react-hook-form";
import { DELIST_MAIL_SUBJECT, ITEM_PER_PAGE } from "../../../data/varible";
import Button from "../../Tools/Button";
import Skeletons1 from "../../Tools/Skeletons/Skeletons1";
import Skeletons2 from "../../Tools/Skeletons/Skeletons2";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
const UserAccount = () => {
  const navigate = useNavigate()
  const rows = useSelector((state) => state.userAccountList?.userAccountList);
  const submitting = useSelector((state) => state.userAccountList?.submitting);
  const [loading, setLoading] = useState(false);
  const [loadingWithoutMail, setLoadingWithoutMail] = useState(false);
  const [active, setActive] = React.useState(localStorage.getItem("userAccountsTab") || "dealer");
  const [show, setShow] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [state, setState] = React.useState({
    id: "",
    label: "",
    heading: "",
    buttonTitle: "",
    option: [],
    type: "",
    modalType: "",
    notes: [],
  });
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const limit = ITEM_PER_PAGE;
  const getData = () => {
    dispatch(
      userAccountList({ page: currentPage, limit: limit, userType: active })
    );
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, [dispatch, currentPage, active]);

  const searchBar = (data) => {
    dispatch(
      userAccountList({
        page: currentPage,
        limit: limit,
        userType: active,
        name: data,
      })
    );
  };
  const dateFilter = (time) => {
    const body = {
      time,
    };
    dispatch(userAccountList);
  };
  console.log(rows);

  const data =
    rows?.users &&
    rows?.users?.map((item) => {
      return {
        ...item,
        profilePicture: (
          <div className="d-flex justify-content-start h-100">
            <img
              style={{ objectFit: "cover" }}
              height={40}
              width={40}
              className="rounded-circle"
              src={item?.profilePicture || "assets/images/ap2.png"}
            />
          </div>
        ),
        // full_name: item.full_name.English,
      };
    });
  const handleSubmit = async (data) => {
    if (loading) return;

    setLoading(true);
    let formData = {};
    switch (state?.modalType) {
      case "warning":
        formData = {
          subject: data?.title,
          message: data?.body,
          userIds: [state?.id],
          type: "0",
          attachments: selectedFile.map((v) => v?.s3URL),
        };
        sendEmail(formData).then((res) => {
          if (res.status) {
            swal("Success", res?.message, "success");
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
      case "delist":
        const updateData = {
          userId: state?.id,
          allowedToLogin: !state?.allowedToLogin,
        };
        updateProfile(updateData).then((res) => {
          if (res?.status) {
            formData = {
              subject: state?.allowedToLogin
                ? DELIST_MAIL_SUBJECT + data?.reason
                : data?.title,
              message: data?.body,
              userIds: [state?.id],
              type: "0",
              attachments: selectedFile.map((v) => v?.s3URL),
            };
            sendEmail(formData);
            getData();
            swal("Success", res?.message, "success");
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
      case "addNote":
        console.log("addnote", data);
        const updateNotes = {
          userId: state?.id,
          adminNotes: { notes: state?.notes },
        };
        updateProfile(updateNotes).then((res) => {
          if (res?.status) {
            getData();
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
    }
  };

  const delistRelistWithoutMail = () => {
    setLoadingWithoutMail(true);
    const updateData = {
      userId: state?.id,
      allowedToLogin: !state?.allowedToLogin,
    };
    updateProfile(updateData).then((res) => {
      if (res?.status) {
        getData();
        swal("Success", res?.message, "success");
        setShow(false);
      } else {
        swal("Error", res?.message, "error");
      }
      setLoadingWithoutMail(false);
    });
  };

  const actions = [
    {
      label: "Issue Warning ?",
      onclick: (id) => {
        setState({
          id: id,
          title: "Issue Warning",
          heading: "Issue Warning ?",
          buttonTitle: "Submit",
          option: issueOption,
          type: "selection",
          modalType: "warning",
        });
        setShow(!show);
      },
    },
    {
      label: "Delist/Relist account ?",
      onclick: (id, row) => {
        const type = row?.allowedToLogin;
        setState({
          id: id,
          heading: type ? "Delist account ?" : "Relist account ?",
          title: type ? "delisting account" : "relisting account",
          buttonTitle: type ? "Delist" : "Relist",
          option: reasonsToDelist,
          type: "selection",
          modalType: "delist",
          allowedToLogin: type,
        });
        setShow(!show);
      },
    },
    // {
    //   label: "Refund for Transcation",
    //   onclick: (id) => {
    //     setState({//       id: id,
    //       title: "Refund for Transcation",
    //       heading: "Refund for Transcation",
    //       buttonTitle: "Refund for Transcation",
    //       option: refundOption,
    //       type: "selection",
    //     });
    //     setShow(!show);
    //   },
    // },
    {
      label: "Add note",
      onclick: (id, data) => {
        setState({
          id: id,
          title: "Add note",
          heading: "Add note",
          buttonTitle: "Save",
          option: [],
          type: "note",
          modalType: "addNote",
          notes: data?.adminNotes?.notes || [],
        });
        setShow(!show);
      },
    },
  ];

  return (
    <div class="overview">
      <TopbarWithDateandSearch
        heading={"User Accounts"}
        searchOnClick={searchBar}
        dateOnclick={dateFilter}
        page={"no"}
      />
      <div className="row">
        <div className="col-lg-4 col-sm-6 col-12">
          <div class="switch-button " style={{ width: "unset" }}>
            <Button
              style={`switch-button-case ${
                active == "dealer" && "activeButton"
              }`}
              title={"Dealers"}
              onClick={() => {
                setActive("dealer")
                localStorage.setItem("userAccountsTab", "dealer")
              }}
            />
            <Button
              style={`switch-button-case ${active == "user" && "activeButton"}`}
              title={"Private users"}
              onClick={() => {
                setActive("user")
                localStorage.setItem("userAccountsTab", "user")
              }}
            />
          </div>
        </div>
      </div>
      <br />
      <BasicTable
        rows={data || []}
        columns={active === "user" ? userAccountColumns : dealerAccountColumns}
        actionType={"dropdown"}
        actions={actions}
        itemperpage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={rows?.totalPages}
        submitting={submitting}
        onRowClick={e => {
          navigate(`/account_details?id=${e?._id}`)
        }}
      />
      <BoostrapModel
        show={show}
        heading={state.heading}
        onHide={() => setShow(!show)}
        style={"w-100"}
        component={
          <ReasonModel
            id={state.id}
            label={state.title}
            buttonTitle={state.buttonTitle}
            option={state.option}
            type={state.type}
            data={state}
            setData={setState}
            handleSubmitEvent={handleSubmit}
            onHide={() => setShow(!show)}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loading={loading}
            loadingWithoutMail={loadingWithoutMail}
            continueWithoutCallback={delistRelistWithoutMail}
          />
        }
      />
    </div>
  );
};
export default UserAccount;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { submitLoginForm } from "../../services/api";
import { ACCESS_TOKEN_NAME } from "../../data/varible";
import { error } from "jquery";

export const loginForm = createAsyncThunk(
  "login/loginForm",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await submitLoginForm(formData);
      if (response.status) {
        if (response.data.userType !== "admin")
          return rejectWithValue("User is not admin");

        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem(`${ACCESS_TOKEN_NAME}`, response.token);
        return response;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    auth: {},
    token: "",
  },
  reducers: {
    setUser: (state, action) => {
      state.auth = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearUser: (state) => {
      state.auth = null;
      state.success = false;
      localStorage.removeItem("user");
    },
    clearToken: (state) => {
      state.token = null;
      state.success = false;
      localStorage.removeItem(`${ACCESS_TOKEN_NAME}`);
    },
    clearStatus: (state) => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginForm.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(loginForm.fulfilled, (state, action) => {
        state.submitting = false;
        state.auth = action.payload.data;
        state.token = action.payload.token;
        state.success = true;
        state.error = null;
      })
      .addCase(loginForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
export const {
  setUser,
  setToken,
  clearStatus,
  clearUser,
  clearToken,
  resetAuthSlice,
} = authSlice.actions;
export default authSlice.reducer;

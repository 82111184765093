import React from "react";
const Button = ({
  type,
  style,
  title,
  onClick,
  inlineStyle,
  btnId,
  disabled,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      id={btnId}
      disabled={disabled}
      className={style}
      style={{ 
        ...inlineStyle,
        backgroundColor: disabled ? "#ebebeb" : ""
       }}
    >
      {title}
    </button>
  );
};
export default Button;

import React from "react";
import { useNavigate } from "react-router";
export default function Security({ data }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/EditSecurity", { state: { data: data } });
  };
  return (
    <div className="overview mt-5">
      <h3 class="pt-0 d-flex justify-content-between">
        Security
        <div role="button" onClick={handleClick}>
          <img src="../assets/images/pencil.png" height={18} className="mx-2" alt="pencil" />
          <span style={{ fontSize: '18px' }}>Edit Security</span>
        </div>
      </h3>
      <div class="my_profile w-100">
        <div class="row">
          <div class="col-sm-3">
            <div class="stp">
              <img src="../assets/images/check-circle2.png" alt="img" />
              <h6>{"Password"}</h6>
              <h6>*************************</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SingleBoxWithImageTitle = ({ parentStyle, childStyle, image, title }) => {
  return (
    <div class={parentStyle}>
      <a class={childStyle}>
        <img src={image} alt="icon" />
        {title}
      </a>
    </div>
  );
};

export default SingleBoxWithImageTitle;

import React, { useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { pageTitles, sidebarItemMenu } from "../../../data/data";
import ListItem from "../../Tools/ListItem";
import $ from "jquery";
import { clearToken, clearUser } from "../../../Reducer/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { myprofile } from "../../../Reducer/profileSilce/profileSilce";
import { Icon } from "@iconify/react/dist/iconify.js";
import SubMenu from "./SubMenu";
const Sidebar = ({ component }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const location = useLocation();
  const logout = () => {
    dispatch(clearToken());
    dispatch(clearUser());
  };
  if (isAuthenticated == "") {
    navigate("/login");
  }
  const profileData = useSelector((state) => state.profile.users);

  // if(profileData && !profileData._id) {
  //   logout()
  // }

  useEffect(() => {
    dispatch(myprofile());
  }, [dispatch]);
  const [activeTab, setActiveTab] = React.useState("Dashboard");
  useEffect(() => {
    const tab = pageTitles.filter((v) => location.pathname === v.path)?.[0]
      ?.title;
    document.title = tab || "";
    setActiveTab(location.pathname);
  }, [location]);

  const resolveItem = (item) => {
    switch (item.type) {
      case "link":
        return (
          <ListItem
            innerhtmlType={"html"}
            listClick={() => setActiveTab(item.path)}
            style={activeTab == item.path && "active"}
            value={
              <Link to={item.path}>
                {activeTab != item.path && (
                  <Icon
                    icon={item.imageOne}
                    fontSize={23}
                    style={{ color: "#353131", margin: "0px 4px 3px 0px" }}
                  />
                )}
                {activeTab == item.path && (
                  <Icon
                    icon={item.imageSecond}
                    fontSize={23}
                    style={{ color: "#437ee8", margin: "0px 4px 3px 0px" }}
                  />
                )}
                {item.title.toUpperCase()}
              </Link>
            }
          />
        );
      case "dropdown":
        return (
          <SubMenu
            title={item.title}
            icon={item.imageOne}
            items={item.children}
            activeTab={activeTab}
          />
        );
    }
  };
  return (
    <div class="container h-100">
      <section class="dashnboard ">
        <div class="desh">
          <div class="hom d-block d-md-none">
            <span class="mnu" onClick={() => $(".sidebar").addClass("main")}>
              <i class="fa-solid fa-bars"></i>
            </span>
            <a class="logo" href="#">
              <img src="assets/images/Logo.png" />
            </a>
          </div>
          {/* <span><h2>{activeTab ? activeTab : "Dashboard"}</h2></span> */}

          <div class="dropdown imgus">
            <button
              style={{ padding: "0px" }}
              class="dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                class="w-100"
                style={{ objectFit: "cover" }}
                src={
                  profileData?.profilePicture &&
                  profileData?.profilePicture !== "N/A"
                    ? profileData?.profilePicture
                    : "assets/images/ap2.png"
                }
                alt="img"
              />{" "}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <NavLink className="dropdown-item" to="/profile">
                  My Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => logout()}
                  className="dropdown-item"
                  to="/login"
                >
                  Log out
                </NavLink>
              </li>
            </ul>
          </div>
          <div class="sidebar">
            <div class="sid1">
              <span
                class="mnu d-block d-md-none"
                onClick={() => $(".sidebar").removeClass("main")}
              >
                <i class="fa-solid fa-xmark"></i>
              </span>
              <NavLink className={"logo"} to={"/"}>
                <img src="assets/images/Logo.png" />
              </NavLink>
              <ul>
                {sidebarItemMenu.map((item) => {
                  return resolveItem(item);
                })}
              </ul>
            </div>
          </div>
          {component}
        </div>
      </section>
    </div>
  );
};

export default Sidebar;

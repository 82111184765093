import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserApi } from '../../services/api';

export const getAllUserFun = createAsyncThunk('/getusers', async (formData, { rejectWithValue }) => {
  try {
    const response = await getUserApi(formData);
    if(response.status) {
      return response.data || []
    } else {
      return response;
    }
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language:'',
    user:[],
    token:"",
    totalPages:"",
    totalCount:0
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getAllUserFun.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAllUserFun.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.user=action.payload?.users;
        state.totalPages=action.payload?.totalPages
        state.totalCount=action.payload?.totalAds
      })
      .addCase(getAllUserFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
  },
});
export default userSlice.reducer;


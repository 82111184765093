import React from "react";
import NumberInput from "../../Tools/NumberInput";

export default function RentPrice({ register, errors, data }) {
  return (
    <div class="other_div other_div2 sp2 row">
      <h3>{("What’s Your Asking Price?")}*</h3>
      <div class={`form-group `}>
        <div class="form-control">
          <label  style={{marginTop: '0px'}}>{("Daily")}*</label>
          <NumberInput
            left={true}
            value={data?.askPrice}
            event={register}
            name={"daily_price"}
            placeholder={("Price ($)")}
          />
          {errors.daily_price && <p>{errors.daily_price?.message}</p>}
        </div>
      </div>
      <div class={`form-group decrement `}>
        <div class="form-control">
          <label  style={{marginTop: '0px'}}>{("Weekly")}*</label>
          <NumberInput
            left={true}
            value={data?.askPrice1}
            event={register}
            name={"weekly_price"}
            placeholder={("Price ($)")}
          />
          {errors.weekly_price && <p>{errors.weekly_price?.message}</p>}
        </div>
      </div>
      <div class={`form-group decrement`}>
        <div class="form-control">
          <label  style={{marginTop: '0px'}}>{("Monthly")}*</label>
          <NumberInput
            left={true}
            value={data?.askPrice2}
            event={register}
            name={"monthly_price"}
            placeholder={("Price ($)")}
          />
          {errors.monthly_price && <p>{errors.monthly_price?.message}</p>}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInputs from "../../Tools/FormInput/Newforminput";
import swal from "sweetalert";
import { changePassword, editProfile, uploadToStorage } from "../../../services/api";
import FormInput from "../../Tools/FormInput";
export default function EditSecurity() {
  const location = useLocation();
  const navigate = useNavigate();
  const meta = location.state.data;
  const [loading, setLoading] = useState(false);
  const [profiledata, setprofile] = useState(meta);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(file);
      };

      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };
  const schema = Yup.object().shape({
    oldPassword: Yup.string().required("Current Password is required"),
    newPassword: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .test(
        "matchPassword",
        "Must be same as new password",
        (v) => v === getValues("newPassword")
      ),
  });
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onError = (errors) => {
    console.log(errors);
  };
  const onSubmitform = async (data) => {
    if (loading) return;
    setLoading(true);
    delete data.confirmPassword
    changePassword(data).then(res => {
      if(res.status) {
        swal('Success', res?.message, 'success')
        navigate('/profile')
      } else {
        swal('Error', res?.message, 'error')
      }
      setLoading(false)
    })
  };

  document.title = "Edit Profile";
  return (
    <div className="overview">
      {profiledata ? (
        <>
          <h3 class="pt-0">
            {"Edit Basic Details"}{" "}
            <span class="saveCancel d-none d-lg-inline">
              <button
                class="save"
                type="button"
                disabled={loading}
                onClick={handleSubmit(onSubmitform, onError)}
              >
                {loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Save"
                )}
              </button>
              <NavLink to="/profile">{"Cancel"}</NavLink>
            </span>
          </h3>

          <div class="my_profile">
            <form onSubmit={handleSubmit(onSubmitform, onError)}>
              <div class="row">
                <div class="col-sm-6 col-xl-3 form-group psd">
                  <h6>{"Current Password"}</h6>
                  <span class="password d-block">
                    <FormInput
                      style={"form-control"}
                      intype={"password"}
                      event={register}
                      // value=""
                      name={"oldPassword"}
                      placeholder={"Current Password"}
                    />
                    {errors.oldPassword && (
                      <p className={`helperText w-100 `}>
                        {errors.oldPassword.message}
                      </p>
                    )}
                  </span>
                </div>
                <div class="col-sm-6 col-xl-3  form-group psd">
                  <h6>{"New Password"}</h6>
                  <span class="password d-block">
                    <FormInput
                      style={"form-control"}
                      intype={"password"}
                      event={register}
                      // value=""
                      name={"newPassword"}
                      placeholder={"New Password"}
                    />
                    {errors.newPassword && (
                      <p className={`helperText w-100`}>
                        {errors.newPassword.message}
                      </p>
                    )}
                  </span>
                </div>
                <div class="col-sm-6 col-xl-3  form-group psd">
                  <h6>{"Confirm Password"}</h6>
                  <span class="password d-block">
                    <FormInput
                      style={"form-control"}
                      intype={"password"}
                      event={register}
                      // value=""
                      name={"confirmPassword"}
                      placeholder={"Confirm Password"}
                    />
                    {errors.confirmPassword && (
                      <p className={`helperText w-100`}>
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </span>
                </div>
                <div class="col-12 d-block d-lg-none">
                  <span
                    class="saveCancel row w-100"
                    style={{
                      marginTop: "0px",
                      marginLeft: "0px",
                      float: "unset",
                    }}
                  >
                    <button
                      className="save w-100 my-2"
                      type="button"
                      onClick={handleSubmit(onSubmitform, onError)}
                      style={{ margin: "0px 0px 0px 0px" }}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <div
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </>
                      ) : (
                        "Save"
                      )}
                    </button>
                    <NavLink
                      className="w-100 my-2"
                      to="/profile"
                      style={{ margin: "0px 0px 0px 0px" }}
                    >
                      {"Cancel"}
                    </NavLink>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

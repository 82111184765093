import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

const PriceSkeletons = () => {
  return (
    <Card
      sx={{
        marginTop: 0,
        // minWidth: "335px",
        // marginRight: 3,
        marginBottom: 4,
        borderRadius: 2,
        boxShadow: "1px 1px 17px 1px #e0e0e0",
      }}
    >
      <CardContent>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box>
                <Skeleton variant="circular" width={50} height={50}></Skeleton>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                  <Skeleton variant="text" sx={{ width: "100%" }}></Skeleton>
                </Typography>
                <Typography
                  sx={{ fontWeight: 500, fontSize: 14, marginTop: 0, float: 'right' }}
                >
                  <Skeleton
                    variant="text"
                    width={100}
                    sx={{ width: "100%" }}
                  ></Skeleton>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{mt: 3}}>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              <Skeleton variant="text" sx={{ width: "100%" }}></Skeleton>
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              <Skeleton variant="text" sx={{ width: "100%" }}></Skeleton>
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box>
            {Array(10)
              .fill()
              .map((v) => {
                return (
                  <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                    <Skeleton variant="text" sx={{ width: "100%" }}></Skeleton>
                  </Typography>
                );
              })}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PriceSkeletons;

import React, { useState } from "react";
import swal from "sweetalert";

//form imports
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import FormGroupInput from "../../Tools/FormGroupInput";
import FormGroupOption from "../../Tools/FormGroupOption";
import {
  editSubscriptonDiscount,
  editSubscriptonPrice,
} from "../../../data/data";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import Button from "../../Tools/Button";
import {
  editSubscriptionFun,
  updateSubscriptionPlan,
} from "../../../services/api";

const EditForm = ({ onHide, rawData, handleSubmitForm, loading }) => {
  const schema = Yup.object().shape({
    price: Yup.string().required().label("Price"),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      price: rawData?.price,
    },
  });
  const [inputList, setInputList] = React.useState([""]);
  const handleAddInput = () => {
    const newInputList = [...inputList, ""];
    setInputList(newInputList);
  };
  const onSubmit = async (data) => {};
  return (
    <div>
      <form className="row" onSubmit={handleSubmit(handleSubmitForm)}>
        {/* <div class="col-sm-12 mb-3">
          <input type="hidden" {...register("id")} value={id} />
          <FormGroupLabelInput
            label={"Ad name"}
            parentStyle={"form-group"}
            childStyle={"form-control"}
            event={register}
            name={"name"}
            placeholder={"Basic 2 Weeks Ad"}
          />
        </div> */}
        {/* <FormGroupOption
          parentStyle={"col-sm-6 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          label={"Price"}
          name={"price"}
          event={register}
          options={editSubscriptonPrice}
        /> */}
        <label htmlFor="planPrice" style={{ position: "relative" }}>
          Price
          <span
            className="h-100"
            style={{
              position: "absolute",
              top: "35px",
              left: "30px",
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            $
          </span>
          <input
            {...register("price")}
            type="text"
            id="planPrice"
            style={{ textIndent: "30px" }}
            className="form-control col-sm-6 mb-3"
          />
        </label>
        {errors.price && <p className="helperText">{errors.price.message}</p>}
        {/* <FormGroupOption
          parentStyle={"col-sm-6 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          label={"Discount"}
          name={"discount"}
          event={register}
          options={editSubscriptonDiscount}
        />
        <div class="col-sm-12 mb-3">
          <h6>{"Features"}</h6>
          {inputList.map((input, index) => (
            <FormGroupInput
              inType="text"
              name={`feature[${index}]`}
              event={register}
              parentStyle={"form-group"}
              ChildStyle={"form-control"}
              placeholder={"Features I go here"}
            />
          ))}
        </div>

        <div class="col-sm-12 mb-3">
          <span class="upFeature" onClick={handleAddInput}>
            {"+ Add new feature"}
          </span>
        </div> */}

        <div class="col-sm-12">
          <div class="modal-footer">
            <Button
              type={"button"}
              style={"btn btn-secondary"}
              onClick={onHide}
              title={"Close"}
            />
            <Button
              disabled={loading}
              type={"submit"}
              style={"btn btn-primary"}
              title={
                loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Update plan"
                )
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditForm;

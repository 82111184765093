import React from "react";

const SearchBar = ({ event, placeholder }) => {
  const [data, setData] = React.useState("");
  return (
    <form>
      <input
        class="form-control me-2"
        type="search"
        placeholder={`${placeholder ? placeholder : "Search"}`}
        aria-label="Search"
        onChange={(e) => setData(e.target.value)}
      />
      <button
        class="btn btn-outline-success bg-light"
        onClick={(e) => {
          e.preventDefault();
          event.onclick(data);
        }}
      >
        <i class="fa-solid fa-magnifying-glass"></i>
      </button>
    </form>
  );
};
export default SearchBar;

import React,{useState} from 'react'
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInputs from '../../Tools/FormInput/Newforminput';
import swal from 'sweetalert';
document.title="Block Words";
 
export default function AddBlockWords() {

    const submitting =false;
    const schema = Yup.object().shape({
        words:Yup.string().required(('words is required'))
   });
   const { register, handleSubmit, formState: { errors } } = useForm({
       resolver: yupResolver(schema),
     });
   
   const onError = (errors) => {
       console.log(errors);
   };
   const onSubmitform = async(data) => {
       if(submitting==false)
       {
           let formData=new FormData();
           formData.append('words',data.words);
           // dispatch(savewords(formData));
           swal('success','words submit successfully', 'success');
       }
   };

  return (
    <div>
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Add Block words
</button>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Block words</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <form  onSubmit={handleSubmit(onSubmitform, onError)}>
         <FormInputs style={'form-control'} intype={'text'}  event={register}  name={'words'} placeholder={('Type words...')}/>        
           {errors.words && <p className="error" style={{color:"red"}}>{errors.words.message}</p>}
         <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
           <button type="submit" class="btn btn-primary">Save</button>
      </div>
         </form>
      </div>
      
    </div>
  </div>
</div>
    </div>
  )
}

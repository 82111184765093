import React from "react";

//form imports
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../../Tools/FormInput";
import Button from "../../Tools/Button";

function PreferencesModal({
  onClose,
  data,
  submitCallback,
  modalFor,
  loading,
}) {
  const { type, item } = data;
  const schema = Yup.object().shape({
    name_en: ["add", "edit"].includes(type)
      ? Yup.string().required("This field is required")
      : Yup.string(),
    name_ar: ["add", "edit"].includes(type)
      ? Yup.string().required("This field is required")
      : Yup.string(),
    name_ku: ["add", "edit"].includes(type)
      ? Yup.string().required("This field is required")
      : Yup.string(),
    year: type === "addYear" ? Yup.string().required().label('Year') : Yup.string(),
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues:
      type === "edit"
        ? {
            name_en: item?.name?.en,
            name_ar: item?.name?.ar,
            name_ku: item?.name?.ku,
          }
        : {},
  });
  const onSubmit = (data) => {
    submitCallback(data);
  };
  const onError = (data) => {
    console.log(data);
  };
  console.log(data);
  return (
    <div>
      {["add", "edit"].includes(type) && (
        <div>
          <p>
            <label className="fw-semibold fs-6">English</label>
            <FormInput
              intype={"text"}
              event={register}
              name={"name_en"}
              style={"form-control"}
              placeholder={"Name"}
            />
            {errors.name_en && (
              <p className="helperText">{errors.name_en.message}</p>
            )}
          </p>
          <p dir="rtl">
            <label className="fw-semibold fs-6">Arabic</label>
            <FormInput
              intype={"text"}
              event={register}
              name={"name_ar"}
              style={"form-control"}
              placeholder={"اسم"}
            />
            {errors.name_ar && (
              <p className="helperText">{errors.name_ar.message}</p>
            )}
          </p>
          <p dir="rtl">
            <label className="fw-semibold fs-6">Kurdish</label>
            <FormInput
              intype={"text"}
              event={register}
              name={"name_ku"}
              style={"form-control"}
              placeholder={"ناو"}
            />
            {errors.name_ku && (
              <p className="helperText">{errors.name_ku.message}</p>
            )}
          </p>
        </div>
      )}
      {type === "delete" && (
        <div>
          <h5>
            Are you sure you want to delete {modalFor} {item?.name?.en}
          </h5>
        </div>
      )}
      <div className="mt-4">
        {["add", "edit"].includes(type) && (
          <Button
            onClick={handleSubmit(onSubmit, onError)}
            style={"themeButton float-end"}
            title={
              loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Submit"
              )
            }
          />
        )}
        {type === "delete" && (
          <Button
            onClick={() => submitCallback()}
            disabled={loading}
            style={"themeButtonNo float-end"}
            title={
              loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Delete"
              )
            }
          />
        )}
        {type === "addYear" && (
          <>
            <p>
              <label className="fw-semibold fs-6">Year</label>
              <FormInput
                intype={"number"}
                event={register}
                name={"year"}
                style={"form-control"}
                placeholder={"Enter Year"}
              />
              {errors.year && (
                <p className="helperText">{errors.year.message}</p>
              )}
            </p>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={loading}
              style={"themeButton float-end"}
              title={
                loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )
              }
            />
          </>
        )}
      </div>
    </div>
  );
}

export default PreferencesModal;

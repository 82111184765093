import React from "react";
import NumberInput from "../../Tools/NumberInput";
import SelectForm from "../../Tools/SelectForm";
export default function SellPrice({
  register,
  errors,
  finance,
  data,
  language,
}) {
  return (
    <div class="other_div other_div2 sp">
      <h3>{"What’s Your Asking Price?"}*</h3>
      <div class="form-group">
        <div class="form-control" style={{ paddingLeft: "12px" }}>
          <NumberInput
            left={true}
            intype={"text"}
            value={data?.askPrice}
            event={register}
            name={"asking_price"}
            placeholder={"IQD"}
          />
          {errors.asking_price && (
            <p className="priceBoxErrors">{errors.asking_price?.message}</p>
          )}
        </div>
      </div>
      <h3 class="mt-4">{"Finance"}</h3>
      <SelectForm
        style={"form-control"}
        event={register}
        name={"finance"}
        selectedOption={data?.properties?.finance}
        values={finance}
      />
      {errors.finance && <p>{errors.finance.message}</p>}
    </div>
  );
}

import { Link } from "react-router-dom";
const KycBox = ({
  parentStyle,
  childStyle,
  title1,
  description,
  title2,
  image,
  buttonName,
  onClick,
  title1Styles
}) => {
  return (
    <div class={parentStyle}>
      <div class={childStyle}>
        <h6 style={title1Styles}><span>{title1}</span> <br /> <span className="fw-semibold fs-5">{description}</span></h6>
        <p  onClick={onClick} style={{cursor: 'pointer'}}>
          {title2}{" "}
          <a >
            <img src={image} alt="eye" /> {buttonName}
          </a>
        </p>
      </div>
    </div>
  );
};
export default KycBox;

import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInputs from "../../Tools/FormInput/Newforminput";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import {
  clearStatus,
  loginForm,
  resetAuthSlice,
} from "../../../Reducer/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitting = useSelector((state) => state.auth.submitting);
  const login = useSelector((state) => state.auth.auth);
  const error = useSelector((state) => state.auth.error);
  const success = useSelector((state) => state.auth.success);

  if (success && login != {}) {
    dispatch(clearStatus());
    swal("success", "Login Successfull", "success");
    navigate("/");
  }
  const schema = Yup.object().shape({
    email: Yup.string().required("email  is required"),
    password: Yup.string().required(" password  is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onError = (errors) => {
    console.log(errors);
  };
  const onSubmitform = async (data) => {
    if (submitting == false) {
      dispatch(loginForm(data));
    }
  };

  if (error) {
    const err = error;
    dispatch(clearStatus());
    switch (error.status) {
      case 403:
        swal("error", error.data.message, "error");
        break;
      default:
        swal("error", err, "error");
        break;
    }
  }
  document.title = "Login";
  return (
    <div>
      <section class="login">
        <div class="container">
          <form
            class="form-signin"
            onSubmit={handleSubmit(onSubmitform, onError)}
          >
            <div className="d-flex justify-content-center  mt-3">
              <img src="assets/images/Logo.png" alt="icon" />
            </div>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade active show"
                id="With_Email"
                role="tabpanel"
                aria-labelledby="With_Email-tab"
              >
                <div class="form-group">
                  <label>Email address</label>
                  <FormInputs
                    style={"form-control"}
                    intype={"email"}
                    event={register}
                    name={"email"}
                    placeholder={"Enter email address"}
                  />
                  {errors.email && (
                    <p className="error" style={{ color: "red" }}>
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <span class="password d-block">
                    <FormInputs
                      style={"form-control"}
                      intype={"password"}
                      event={register}
                      name={"password"}
                      placeholder={"Enter password "}
                    />
                    {errors.password && (
                      <p className="error" style={{ color: "red" }}>
                        {errors.password.message}
                      </p>
                    )}

                    <i class="far fa-eye"></i>
                  </span>
                </div>
                <button class="btn " submit="">
                  Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

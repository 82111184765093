import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import BoxItem from "../../Tools/BoxList";
import BoxImageTitle from "../../Tools/BoxImageTitle";
import { keyStatsFun } from "../../../Reducer/dashboard/dashboardSlice";
import SingleBoxWithImageTitle from "../../Tools/SingleBoxWithImagetitle";
import Skeletons1 from "../../Tools/Skeletons/Skeletons1";
import Skeletons2 from "../../Tools/Skeletons/Skeletons2";
const KeyStats = ({ data, loading }) => {
  // const data = useSelector((state) => state.dashboard?.keyStats);
  // const error = useSelector(state => state.dashboard.error);
  // console.log("data", data)
  // const dispatch = useDispatch();
  // React.useEffect(()=>{
  //     dispatch(keyStatsFun({time: "This week"}));
  // },[dispatch]);
  // const onsubmit = (data) => {
  //     const formData = new FormData();
  //     formData.append('time', data);
  //     dispatch(keyStatsFun(formData));
  // }
  // if(error && error.status)
  // {
  //   switch (error.status) {
  //     case 403:
  //         toast.error(error.data.message);
  //       break;
  //     default:
  //         toast.error("Something went wrong");
  //       break;
  //   }
  // }
  return (
    <div class="set1">
      <h3 class="trade">
        <HeadingWithDatePicker
          heading={"Key stats"}
          event={{ onclick: onsubmit }}
        />
      </h3>
      {loading ? (
        <>
        <Skeletons1 lines={1} />
        <Skeletons2 lines={1} />
        <Skeletons1 lines={1} />
        <Skeletons2 lines={1} />
        </>
      ) : (
        <>
          <BoxItem
            parentStyle={"col-sm-2"}
            childStyle={"st"}
            data={data?.dealer?.stats}
            heading={"Dealers posting ads"}
          />
          <BoxImageTitle
            parentStyle={"row mt-3"}
            childStyle={"col-sm-3"}
            data={data?.dealer?.additionalInfo}
          />
          <BoxItem
            parentStyle={"col-sm-2"}
            childStyle={"st"}
            data={data?.users?.stats}
            heading={"Private users posting ads"}
          />
          <BoxImageTitle
            parentStyle={"row mt-3"}
            childStyle={"col-sm-3"}
            data={data?.users?.additionalInfo}
          />
          <div class="row">
            <SingleBoxWithImageTitle
              parentStyle={"col-lg-12"}
              childStyle={"carsold"}
              image={"assets/images/dash_img/car (1) 1.png"}
              title={data?.total_car_sold?.value + data?.total_car_sold?.title}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default KeyStats;

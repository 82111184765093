import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userAccountListFun } from "../../services/api";

export const userAccountList = createAsyncThunk(
  "/user_account",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await userAccountListFun(formData);
      if (response.status) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userAccountSlice = createSlice({
  name: "userAccountList",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language: "",
    userAccountList: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userAccountList.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(userAccountList.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.userAccountList = action.payload;
      })
      .addCase(userAccountList.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      });
  },
});
export default userAccountSlice.reducer;

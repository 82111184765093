import HeadingWithDatePicker from "../DatepickerWithHeading";

const TradeBox = ({
  onClick,
  component,
  heading,
  parentStyle,
  childStyle,
  inlineStyle,
  heading2,
}) => {
  return (
    <>
      <div class={parentStyle} style={inlineStyle}>
        <h3 class={childStyle}>
          <HeadingWithDatePicker
            heading={heading}
            event={{ onclick: onClick }}
          />
          {heading2}
        </h3>
        {component}
      </div>
    </>
  );
};
export default TradeBox;

const FormGroupInput = ({
  parentStyle,
  ChildStyle,
  inType,
  value,
  event,
  name,
  placeholder,
}) => {
  return (
    <div class={parentStyle}>
      <input
        type={inType}
        class={ChildStyle}
        {...event(name)}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

export default FormGroupInput;

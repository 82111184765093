import { fromAddress } from "react-geocode";
import {
  getAdTypes,
  getColors,
  getEntityTypes,
  getFeatures,
  getMake,
  getYears,
} from "../services/api";
import advertFormStaticData from "./advertFormStaticData.json";
import { cities } from "./data";
import withoutVin from "./advertFormStaticData.json";

export function isImageOrVideo(url) {
  // Extract the file extension from the URL
  const extension = url?.split?.(".").pop().toLowerCase();

  // Check if the extension corresponds to an image or video format
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const videoExtensions = ["mp4", "avi", "mkv", "mov", "webm"];

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
}

export const handleDownload = (url) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = true;
  a.target = "_blank";
  a.click();
};

export function getYearsFrom1950ToCurrent() {
  const currentYear = new Date().getFullYear();
  const startYear = 1950;

  const years = [];
  let i = 1;
  for (let year = currentYear + 1; year >= startYear; year--, i++) {
    years.push({ id: i, name: { en: year, ar: year, ku: year }, value: year });
  }
  return years;
}

export const fetchAdvertFormData = async () => {
  let response = advertFormStaticData;
  const allApiData = await Promise.all([
    getMake({ isLive: false, limit: 200 }),
    getEntityTypes(),
    getFeatures(),
    getColors(),
    getYears(),
  ]);
  let adData = await getAdTypes();

  response.data["make"] = allApiData[0]?.makes;
  response.data["body"] = allApiData[1]?.data;
  response.data["features"] = allApiData[2]?.data?.features || [];
  response.data["colour"] = allApiData[3]?.data?.colors;
  response.data["yearsList"] = allApiData[4]?.data?.years;
  response.data["adTypes"] = adData.data;
  response.data["cities"] = cities

  console.log(response);
  return response;
};

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function convertDigitsToEnglish(text) {
  // Define a mapping of Arabic/Kurdish digits to English digits
  const digitMap = {
    "٠": "0",
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
  };

  const regex = /[٠١٢٣٤٥٦٧٨٩]/g;

  const convertedText = text.replace(
    regex,
    (match) => digitMap[match] || match
  );

  return convertedText;
}

export function camelToNormal(inputString) {
  if (!inputString) return "";
  // Use a regular expression to add spaces before uppercase letters
  const readableString = inputString.replace(/([A-Z])/g, " $1");

  // Convert the first character to uppercase and remove leading space if any
  return (
    readableString.charAt(0).toUpperCase() + readableString.slice(1).trim()
  );
}

export async function translateInTwoLanguages(from, text) {
  console.log(from);
  console.log(text);
  let toLangsArray = [];
  if (from === "ku") from = "ckb";

  if (from == "en") {
    toLangsArray = ["ar", "ckb"];
  } else if (from == "ar") {
    toLangsArray = ["en", "ckb"];
  } else if (from == "ckb") {
    toLangsArray = ["en", "ar"];
  }

  function data() {
    return new Promise((resolve, reject) => {
      resolve(googleTranslate(from, toLangsArray[0], text));
      reject("error");
    });
  }

  function data1() {
    return new Promise((resolve, reject) => {
      resolve(googleTranslate(from, toLangsArray[1], text));
      reject("error");
    });
  }

  let translatedText = await data();
  let translatedText1 = await data1();

  let translatedObj = {
    [toLangsArray[0]]: translatedText.data.translations[0].translatedText,
    [toLangsArray[1]]: translatedText1.data.translations[0].translatedText,
    [from]: text,
  };
  translatedObj = { ...translatedObj, ku: translatedObj?.ckb };

  delete translatedObj["ckb"];

  return translatedObj;
}

export const googleTranslate = async (from, to, text1) => {
  let fromLang = from;
  let toLang = to; // translate to norwegian
  let text = text1;

  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
  url += "&q=" + encodeURI(text);
  url += `&source=${fromLang}`;
  url += `&target=${toLang}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      console.log("There was an error with the translation request: ", error);
    });
};
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatPrice(lang, price) {
  if (isNaN(price)) price = 0;
  if (parseInt(price) === NaN) return "Any";

  const fprice =
    lang === "en"
      ? `${"IQD"} ${numberWithCommas(price)}`
      : `${numberWithCommas(price)} ${"IQD"}`;

  return fprice;
}

export const openAddressOnMap = (address) => {
  if (!address) return;
  fromAddress(address)
    .then(({ results }) => {
      const { lat, lng } = results[0].geometry.location;
      console.log(lat, lng);
      window.open("https://maps.google.com?q=" + lat + "," + lng);
    })
    .catch(console.error);
};

export const getNameFromVal = (obj, val, lang) => {
  return withoutVin?.data?.[obj]?.filter((v) => v.value == val)?.[0]?.name?.[
    lang
  ];
};

export const getFeatureName = (name) => {
  const objBasic = withoutVin.data.basicDetails.filter(
    (v) => v.value === name
  )[0];
  const objSpeci = withoutVin.data.specifications.filter(
    (v) => v.value === name
  )[0];
  const objFeatures = withoutVin.data.features.filter(
    (v) => v.value === name
  )[0];
  return objBasic?.name || objSpeci?.name || objFeatures?.name || null;
};

export function toCamelCase(str) {
  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (/\s+/.test(match)) return ""; // Remove spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}


export function generatePagination(totalPages, currentPage) {
  const paginationArray = [];
  const firstPagesToShow = 1;
  const lastPagesToShow = 1;
  const pagesAroundCurrent = 1;

  // Add first couple of pages
  for (let i = 1; i <= firstPagesToShow; i++) {
      paginationArray.push(i);
  }
  if(currentPage > 2) {
    paginationArray.push("..");
  }
  // Add pages around the currently selected page
  for (let i = currentPage - pagesAroundCurrent; i <= currentPage + pagesAroundCurrent; i++) {
      if (i > firstPagesToShow && i < totalPages - lastPagesToShow + 1) {
          paginationArray.push(i);
      }
  }
  if(currentPage < totalPages - 2) {
    paginationArray.push("..");
  }
  // Add last couple of pages
  for (let i = totalPages - lastPagesToShow + 1; i <= totalPages; i++) {
      paginationArray.push(i);
  }

  // Remove duplicates and sort the array
  const uniquePaginationArray = [...new Set(paginationArray)].sort((a, b) => a - b);
  return paginationArray.includes("..") ? paginationArray : uniquePaginationArray

}
import SubscriptionBox from "../../Tools/SubscriptionBox";
const Subscription = ({ data, onClick, type }) => {
  const styles = {
    basic: {},
    standard: {},
    premium: { background: "#4C7EE8", color: "white" },
    ultimate: { background: "#FCBC00" },
  };
  return (
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="Trade"
        role="tabpanel"
        aria-labelledby="Trade-tab"
      >
        <div class="row">
          {data &&
            data?.length > 0 &&
            data.map((item) => {
              const itemStyle = styles[item?.name.toLowerCase()];
              return (
                <SubscriptionBox
                  parentSTyle={`${
                    type === "checkMyCar" ? "col-12" : "col-md-6 col-xxl-3 "
                  } mb-3`}
                  childStyle={"set"}
                  id={item?._id}
                  style={itemStyle}
                  title={item?.name}
                  time={item?.time}
                  description={item?.description}
                  pricing={item?.price}
                  benefits={item?.additionalInfo?.features}
                  duration={item?.duration}
                  item={item}
                  onClick={() => onClick(item)}
                  type={type}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Subscription;

import { Link } from "react-router-dom";

const TopBar = ({ heading, image, style }) => {
  return (
    <h2 class={style}>
      <Link to="/approval_que">
        <img src={image} />
      </Link>{" "}
      {heading}
    </h2>
  );
};
export default TopBar;

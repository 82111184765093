import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import {
  getFeatureName,
  getNameFromVal,
  numberWithCommas,
  translateInTwoLanguages,
} from "../../../data/utils";
import { getFeatures } from "../../../services/api";

export default function CarDetailModel({ data, title }) {
  // data = [data?.basicDetails, data?.specifications, data?.features];
  const [allDetails, setAllDetails] = useState(null);
  const setTitle = (i) => {
    switch (i) {
      case 0:
        return "Basic Details";
      case 1:
        return "Specifications";
      case 2:
        return "Features";
    }
  };

  const defElse = {
    en: "",
    ar: "",
    ku: "",
  };
  const [elseState, setElse] = useState(defElse);
  useEffect(() => {
    if (data?.[2]?.anyThingElse) {
      translateInTwoLanguages("en", data?.[2]?.anyThingElse).then((res) => {
        setElse(res);
      });
    } else {
      setElse(defElse);
    }
    getFeatures().then((res) => {
      if (res?.status) {
        // setFeaturesData(res?.data?.features)
        let reqFeatures = {};
        const features = res?.data?.features;
        features.forEach((v) => {
          if (data?.features?.[v?.value]) {
            reqFeatures[v?.name?.["en"]] = true;
          } else {
            reqFeatures[v?.name?.["en"]] = false;
          }
        });
        setAllDetails([data?.basicDetails, data?.specifications, reqFeatures]);
      }
    });
  }, []);
  const makeValue = (name, value) => {
    // if (name === "color") {
    //   return (
    //     <li className="list-group-item d-flex justify-content-between align-items-center">
    //       {"Colour"}
    //       <span
    //         className="float-end w-25"
    //         style={{
    //           backgroundColor: value,
    //           height: "25px",
    //           border: "2px solid lightgrey",
    //           borderRadius: "5px",
    //         }}
    //       ></span>
    //     </li>
    //   );
    // }
    if (name === "carDescription") {
      // return (
      //   <div className="row px-3 w-100 pt-2">
      //     <div className="col-sm-4">
      //       <h6>{t('Car Description')}</h6>
      //     </div>
      //     <div className="col-sm-8">
      //       <p>{value?.["en"]}</p>
      //     </div>
      //   </div>
      // );
      return null;
    }
    if (name === "anyThingElse") {
      // const translatedValue = await translateInTwo"en"uages("en", value)
      // console.log(translatedValue)
      return null;
      if (elseState?.["en"]?.length == 0) {
        return null;
      }
      return (
        <div className="row px-3 w-100 pt-2">
          <div className="col-sm-4">
            <h6>{"Anything else"}</h6>
          </div>
          <div className="col-sm-8">
            <p>{elseState?.["en"]}</p>
          </div>
        </div>
      );
    }
    if (name === "engineSize") {
      if (!value) return null;
      const obj = name.replace("engine", "").toLowerCase();
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          {getFeatureName(name)}
          <span className="text-end">
            {value ? getNameFromVal(obj, value, "en") : "--"}
          </span>
        </li>
      );
    }
    if (name === "enginePower") {
      if (!value) return null;
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          {getFeatureName(name)}
          <span className="text-end">{value ? `${numberWithCommas(value)} ${"bhb"}` : "--"}</span>
        </li>
      );
    }
    if (name === "km") {
      if (!value) return null;
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          {("Kilometres")}
          <span className="text-end">
            {value ? `${numberWithCommas(value)} ${("KM's")}` : "--"}
          </span>
        </li>
      );
    }
    const type = typeof value;
    switch (type) {
      case "boolean":
        return value ? (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {name}{" "}
            <span>
              <img
                src="assets/images/check.webp"
                className="text-end"
                height={15}
                alt=""
              />
            </span>
          </li>
        ) : null;
      case "string":
        if (!value) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {getFeatureName(name)}
            <span className="text-end">
              {value && value?.length > 0 ? value : "--"}
            </span>
          </li>
        );
      case "number":
        if (!value) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {getFeatureName(name)}
            <span className="text-end">{value ? value : "--"}</span>
          </li>
        );
      case "object":
        if (!value?.["en"]) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {getFeatureName(name)}
            <span className="text-end">
              {value?.["en"] ? value?.["en"] : "--"}
            </span>
          </li>
        );
    }
  };

  const [accordion, setAccordion] = useState([]);
  const toggleAccordion = (e) => {
    if (accordion.includes(e)) {
      setAccordion([...accordion.filter((v) => v !== e)]);
    } else {
      setAccordion([...accordion, e]);
    }
  };
  console.log(data);
  return (
    <div
      class="modal fade modalNoThumb"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog specifications car-detail-modal ">
        <div class="modal-content" style={{ paddingTop: "18px" }}>
          <div class="modal-header d-flex justify-content-end">
            <a class="btnclose mb-3" data-bs-dismiss="modal" aria-label="Close">
              <img
                src="../assets/images/close_img.png"
                alt="icon"
                height={22}
              />
            </a>
          </div>
          <div class="modal-body">
            <div className="specifications">
              <Accordion open={accordion} toggle={toggleAccordion}>
                {allDetails &&
                  allDetails?.map((item, index) => {
                    return (
                      <AccordionItem>
                        <AccordionHeader targetId={index + 1}>
                          <img class="plus" src="../assets/images/plus.png" />
                          <img
                            style={{ display: "none" }}
                            src="../assets/images/cls.png"
                          />
                          {setTitle(index)}
                        </AccordionHeader>
                        <AccordionBody accordionId={index + 1}>
                          <ul className="d-none d-sm-block w-100">
                            {Object.keys(item).map((val) => {
                              let v = item?.[val];
                              if (v === "true") v = true;
                              if (v === "false") v = false;
                              const valu = makeValue(val, v);
                              return valu;
                            })}
                          </ul>
                          <div
                            className="d-block d-sm-none list-group"
                            style={{ listStyleType: "none" }}
                          >
                            {Object.keys(item).map((val) => {
                              let v = item?.[val];
                              if (v === "true") v = true;
                              if (v === "false") v = false;
                              const valu = makeValue(val, v);
                              return valu;
                            })}
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

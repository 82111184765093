const BoxItem = ({ parentStyle, childStyle, heading, data }) => {
  return (
    <>
      {heading && <h6>{heading}</h6>}
      <div className="row">
        {data?.length > 0 &&
          data.map((i) => {
            return (
              <div className={parentStyle}>
                <div className={childStyle+" d-flex flex-column justify-content-between"} style={{ position: "relative", height: "105px" }}>
                  <div
                    // className="d-flex justify-content-evenly"
                    style={{
                      position: "absolute",
                      right: "10px",
                      // top: "5px",
                      width: "90px",
                    }}
                  >
                    {i?.hasMore &&
                      i?.more.map((v) => {
                        return (
                          <span
                            className="d-flex justify-content-between align-items-center rounded-1"
                            style={{ fontSize: "11px", fontWeight: "600", padding: "0px 1px 1px 1px" }}
                          >
                            {v?.title}{" "}
                            <h5
                              style={{ fontSize: "11px", marginBottom: "0px", backgroundColor: v?.badgeColor, minWidth: "50px", paddingBlock: "1px" }}
                              className="text-white px-2 rounded-5 text-center"
                            >
                              {v?.value}
                            </h5>
                          </span>
                        );
                      })}
                  </div>
                  <h5 className="fs-3">{i.value}</h5>
                  <p>{i.title}</p>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
export default BoxItem;

import React, { useEffect, useState } from "react";
import LiveTrafffic from "./liveTraffic";
import Metrics from "./metrics";
import KeyStats from "./keyStats";
import Overview from "./overview";
import { getDashboardData } from "../../../services/api";
import { formatDashboardData } from "./dataFormattingUtil";
import { toCamelCase } from "../../../data/utils";
document.title = "dashboard";

const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setData] = useState({});
  useEffect(() => {
    setLoading(true);
    getDashboardData().then((res) => {
      if (res.status) {
        const formattedData = formatDashboardData(res?.data);
        setData(formattedData);
      }
      setLoading(false);
    });
  }, []);
  return (
    <>
      <Overview data={dashboardData?.overview} loading={loading} />
      <KeyStats data={dashboardData?.keyStats} loading={loading} />
      {/* <Metrics data={dashboardData?.metrics} loading={loading} /> */}
      {/* <LiveTrafffic /> */}
    </>
  );
};

export default DashboardPage;

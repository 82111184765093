import { Icon } from "@iconify/react/dist/iconify.js";
import ViewFullscreen from "../../Tools/ViewFullscreen";
import moment from "moment";
const BasicDetails = ({ heading, row, parentStyle, childStyle, column }) => {
  let picture = row?.profilePicture || "/assets/images/ap2.png";
  row = {
    ...row,
    dateOfBirth: moment.unix(row.dateOfBirth).format("DD/MMMM/YYYY"),
    website: row?.additionalInfo?.website,
  };
  return (
    <div class={parentStyle}>
      <h3>{heading}</h3>
      <div className="row">
        <div className="col-lg-3 mb-3 ">
          <h6> Profile Picture</h6>
          <ViewFullscreen
            disabled={!row?.profilePicture}
            url={row?.profilePicture || "/assets/images/ap2.png"}
          >
            <img
              src={row?.profilePicture || "/assets/images/ap2.png"}
              alt=""
              className="w-100 h-100 rounded-2"
              height={150}
              style={{ objectFit: "contain" }}
            />
          </ViewFullscreen>
        </div>
        <div className="col-lg-9">
          <div class="row">
            {row &&
              column.length > 0 &&
              column.map((item) => {
                return (
                  <div class={item.style ? item.style : childStyle}>
                    <h6
                      className="d-flex align-items-center"
                      style={{ height: "20px" }}
                    >
                      <span>{item.label}</span>
                      <span className="mx-2">
                        {item?.verified &&
                          row?.[item.name] &&
                          row?.[item?.verifiedKey] && (
                            <Icon
                              icon="ic:baseline-verified"
                              // className="w-25"
                              style={{
                                color: "#4C7EE8",
                                fontSize: "20px",
                                margine: "0px 0px 0px 0px",
                              }}
                            />
                          )}
                      </span>
                    </h6>
                    <p
                      className={`${
                        item?.visit &&
                        row?.[item.name] &&
                        !row?.[item.name].includes("undefined")
                          ? "link-primary"
                          : ""
                      }`}
                      style={{ overflow: "hidden" }}
                      role={item?.visit ? "button" : ""}
                      onClick={() =>
                        item?.visit &&
                        row?.[item.name] &&
                        !row?.[item.name].includes("undefined")
                          ? window.open(row?.[item.name])
                          : null
                      }
                    >
                      {row[item.name] || "--"}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {row?.userType == "dealer" && (
        <div className="mt-4">
          <h3>Cover Picture</h3>
          <ViewFullscreen
            disabled={!row?.coverPicture}
            url={row?.coverPicture || "/assets/images/img-not-found.png"}
          >
            <img
              src={row?.coverPicture || "/assets/images/img-not-found.png"}
              height={row?.coverPicture ? 300 : 100}
              className="w-100 rounded-2 "
              style={{ objectFit: row?.coverPicture ? "cover" : "contain" }}
              alt=""
            />
          </ViewFullscreen>
        </div>
      )}
    </div>
  );
};
export default BasicDetails;

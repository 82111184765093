import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import TopBar from "../../Tools/TopBar";
import BasicDetails from "../../Tools/BasicTable";
import { singlePendingApprovalFun } from "../../../Reducer/user/singlePendingApprovalSlice";
import { dealerAccountBasicDetailsColumn, singlePendingApprovalUser } from "../../../data/data";
import KycDocument from "./kycDocument";
import { useLocation } from "react-router-dom";

const SinglePendingApprovalUser = () => {
  const test = useSelector((state) => state.singlePendingApproval);
  //   let row = useSelector(
  //     (state) => state.singlePendingApproval?.singlePendingApproval
  //   );
  const location = useLocation();
  const row = {
    ...location?.state?.item,
    gender: location?.state?.item?.gender?.name?.en
  };
  const error = useSelector((state) => state.user?.error);
  const urlString = window.location.href;
  const urlSegments = urlString.split("/");
  const lastItem = urlSegments[urlSegments.length - 1];
  console.log(row);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const body = {
      id: lastItem,
    };
    dispatch(singlePendingApprovalFun(body));
  }, []);

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error("Something went wrong");
        break;
    }
  }
  return (
    <>
      <div class="overview account_details">
        <TopBar
          style={"mb-5"}
          heading={"Pending dealer account approvals"}
          image={"../assets/images/backbt.png"}
        />
        <BasicDetails
          heading={"Basic Details"}
          row={row}
          column={dealerAccountBasicDetailsColumn}
          parentStyle={"set_list mb-4"}
          childStyle={"col-6 col-lg-4 col-xl-4 mb-4"}
        />
        <KycDocument row={row} />
      </div>
    </>
  );
};
export default SinglePendingApprovalUser;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUsersFun } from "../../services/api";

export const sendNotificationUser = createAsyncThunk("/getusers", async (formData) => {
  try {
    const response = await getUsersFun(formData);
    console.log("response", response);
    if (response.status) {
      return response?.data?.users || [];
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
  }
});

const sendNotificationSlice = createSlice({
  name: "sendNotificationusers",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language: "",
    users: [],
    // tradeUsers:[],
    // businessUsers: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendNotificationUser.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(sendNotificationUser.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.users = action.payload;
        // state.tradeUsers=action.payload.trade_users;
        // state.businessUsers=action.payload.Bussiness_users;
      })
      .addCase(sendNotificationUser.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      });
  },
});
export default sendNotificationSlice.reducer;

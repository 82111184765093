import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import "./App.css"
import { setToken, setUser } from "./Reducer/auth/authSlice";
import { ACCESS_TOKEN_NAME } from "./data/varible";
const user =
  localStorage.getItem("user") !== "undefined"
    ? JSON.parse(localStorage.getItem("user"))
    : "";
const token = localStorage.getItem(`${ACCESS_TOKEN_NAME}`)
  ? localStorage.getItem(`${ACCESS_TOKEN_NAME}`)
  : "";

if (user && token) {
  store.dispatch(setUser(user));
  store.dispatch(setToken(token));
} else if (window.location.pathname !== "/login" && user == null) {
  window.location.href = "/login";
}
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

import { styled } from "@mui/material";

export const transactionData = [
  {
    id: 1,
    email: "sahilpatel6@gmail.com",
    materialType: "Plastic",
    weight: "120 kg",
    price: "Rs 30/kg",
    totalAmount: "3600",
  },
  {
    id: 1,
    email: "test2@gmail.com",
    materialType: "Plastic",
    weight: "121 kg",
    price: "Rs 30/kg",
    totalAmount: "3600",
  },
  {
    id: 1,
    email: "test3@gmail.com",
    materialType: "Plastic",
    weight: "110 kg",
    price: "Rs 30/kg",
    totalAmount: "1500",
  },
  {
    id: 1,
    email: "seconemail@gmail.com",
    materialType: "Plastic",
    weight: "100 kg",
    price: "Rs 30/kg",
    totalAmount: "2000",
  },
  {
    id: 1,
    email: "helloworld@gmail.com",
    materialType: "Plastic",
    weight: "90 kg",
    price: "Rs 30/kg",
    totalAmount: "2500",
  },
];

export const headerWithDatePickerData = [
  "This week",
  "This month",
  "This year",
];

export const sidebarItemMenu = [
  {
    active: 1,
    imageOne: "ic:outline-dashboard",
    imageSecond: "ic:baseline-dashboard",
    title: "DASHBOARD",
    path: "/",
    type: "link",
  },
  {
    active: 2,
    imageOne: "mdi:users-outline",
    imageSecond: "mdi:users",
    title: "USER ACCOUNTS",
    path: "/user_accounts",
    type: "link",
  },
  {
    active: 3,
    imageOne: "fluent:people-queue-24-regular",
    imageSecond: "fluent:people-queue-24-filled",
    title: "Accounts approval que",
    path: "/approval_que",
    type: "link",
  },
  {
    active: 4,
    imageOne: "ion:list-circle-outline",
    imageSecond: "ion:list-circle",
    title: "All Adverts",
    path: "/adverts",
    type: "link",
  },
  {
    active: 5,
    imageOne: "heroicons:queue-list",
    imageSecond: "heroicons:queue-list-solid",
    title: "Adverts approval que",
    path: "/ad_approval_que",
    type: "link",
  },
  {
    active: 6,
    imageOne: "iconamoon:notification",
    imageSecond: "iconamoon:notification-fill",
    title: "Send notifications",
    path: "/send_notifications",
    type: "link",
  },
  {
    active: 7,
    imageOne: "material-symbols:price-change-outline-rounded",
    imageSecond: "material-symbols:price-change-rounded",
    title: "Pricing controls",
    path: "/pricing_controls",
    type: "link",
  },
  {
    active: 8,
    imageOne: "pajamas:preferences",
    imageSecond: "material-symbols:price-change-rounded",
    title: "Specifications",
    path: "/pricing_controls",
    type: "dropdown",
    children: [
      {
        active: 1,
        imageOne: "ion:car-sport",
        imageSecond: "ion:car-sport-outline",
        title: "Makes & Models",
        path: "/makes",
      },
      {
        active: 2,
        imageOne: "heroicons-solid:color-swatch",
        imageSecond: "heroicons-outline:color-swatch",
        title: "Colours",
        path: "/colors",
      },
      {
        active: 3,
        imageOne: "material-symbols:feature-search",
        imageSecond: "material-symbols:feature-search-outline",
        title: "Features",
        path: "/features",
      },
      {
        active: 4,
        imageOne: "clarity:date-solid",
        imageSecond: "clarity:date-line",
        title: "Make Years",
        path: "/makeYears",
      },
    ],
  },
  // {
  //     active: 8,
  //     imageOne: 'assets/images/dash_img/photograph.png',
  //     imageSecond: 'assets/images/dash_img/photograph_C.png',
  //     title: "Banner images",
  //     path: '/banner_images'
  // },
  // {
  //     active: 9,
  //     imageOne: 'assets/images/dash_img/document.png',
  //     imageSecond: 'assets/images/dash_img/document_C.png',
  //     title: "Pages",
  //     path: '/pages'
  // },
  // {
  //     active: 10,
  //     imageOne: 'assets/images/dash_img/flag.png',
  //     imageSecond: 'assets/images/dash_img/flag_C.png',
  //     title: "Block words",
  //     path: '/block_words'
  // }
];

export const pageTitles = [
  { title: "Dashboard", path: "/" },
  { title: "User accounts", path: "/user_accounts" },
  { title: "Approval que", path: "/approval_que" },
  { title: "Send notifications", path: "/send_notifications" },
  { title: "Pricing controls", path: "/pricing_controls" },
  { title: "Profile", path: "/profile" },
  { title: "Edit profile", path: "/EditProfile" },
  { title: "Edit security", path: "/EditSecurity" },
  { title: "Account details", path: "/account_details" },
  { title: "Review documents", path: "/reveiw_approval_que" },
  { title: "Makes & Models", path: "/makes" },
  { title: "Colours", path: "/colors" },
  { title: "Features", path: "/features" },
  { title: "Make Years", path: "/makeYears" },
  { title: "All Adverts", path: "/adverts" },
];

export const keyStatsPosignData = [
  {
    title: "155",
    description: "Basic 2 weeks ad",
  },
  {
    title: "145",
    description: "Basic 3 weeks ad",
  },
  {
    title: "155",
    description: "Basic 4 weeks ad",
  },
  {
    title: "135",
    description: "Basic 6 weeks ad",
  },
];

export const metricsData = [
  {
    title: "451",
    description: "Views on selling cars",
  },
  {
    title: "500",
    description: "Views on rental cars",
  },
  {
    title: "500",
    description: "Views on checking cars",
  },
  {
    title: "3200",
    description: "Views on website",
  },
  {
    title: "$25,0000",
    description: "Views on app",
  },
  {
    title: "500",
    desctiption: "Total views",
  },
];

export const overviewData = [
  {
    title: "500",
    description: "Total active ads",
  },
  {
    title: "500",
    description: "Total users",
  },
  {
    title: "500",
    description: "Business users",
  },
  {
    title: "500",
    description: "Individual users",
  },
  {
    title: "$25,0000",
    description: "Total payments received",
  },
  {
    title: "500",
    description: "Total Views",
  },
];
export const approvalUserColumn = [
  {
    label: "Picture",
    name: "profilePicture",
  },
  {
    label: "DATE",
    name: "createdAt",
  },
  {
    label: "Name",
    name: "fullName",
  },
  {
    label: "Company Name",
    name: "dealerShipName",
  },
  {
    label: "EMAIL",
    name: "email",
  },
  {
    label: "PHONE",
    name: "phoneNumber",
  },
  {
    label: "LOCATION",
    name: "address",
  },
];
export const aprrovalAdvertColumn = [
  {
    label: "DATE",
    name: "createdAt",
  },
  {
    label: "ITEM",
    name: "item",
  },
  {
    label: "AD TYPE",
    name: "adType",
    style: "text-nowrap",
  },
  {
    label: "Posted by",
    name: "name",
  },
  {
    label: "User type",
    name: "userType",
    style: "text-nowrap",
  },
  {
    label: "PHONE",
    name: "phoneNumber",
  },
  {
    label: "LOCATION",
    name: "address",
  },
];

export const pagesColumn = [
  {
    label: "PAGE NAME",
    name: "title",
  },
  {
    label: "LAST UPDATED",
    name: "last_updated",
  },
];

export const blockWordsColumn = [
  {
    label: "Words",
    name: "words",
  },
  {
    label: "Date",
    name: "date",
  },
  // {
  //     label: "Action",
  //     name: "action"
  // },
];

export const singlePendingApprovalUser = [
  {
    label: "Name",
    name: "fullName",
  },
  {
    label: "Company Name",
    name: "dealerShipName",
  },
  {
    label: "Username",
    name: "userName",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Phone",
    name: "phoneNumber",
  },
  {
    label: "Address",
    name: "address",
  },
];

export const userAccountColumns = [
  {
    label: "Picture",
    name: "profilePicture",
    navigate: true,
    path: "/account_details",
    // style: "col-lg-5",
  },
  {
    label: "USER NAME",
    name: "fullName",
    navigate: true,
    path: "/account_details",
    style: "col-lg-4",
  },
  {
    label: "EMAIL",
    name: "email",
    navigate: true,
    path: "/account_details",
    verified: true,
    verifiedKey: "emailVerified",
  },
  {
    label: "PHONE",
    name: "phoneNumber",
    verified: true,
    verifiedKey: "phoneNumberVerified",
  },
  {
    label: "LOCATION",
    name: "address",
    style: "col-lg-12",
  },
];
export const dealerAccountColumns = [
  {
    label: "Picture",
    name: "profilePicture",
    navigate: true,
    path: "/account_details",
    // style: "col-lg-5",
  },
  {
    label: "USER NAME",
    name: "fullName",
    navigate: true,
    path: "/account_details",
    style: "col-lg-4",
  },
  {
    label: "Company Name",
    name: "dealerShipName",
    navigate: true,
    path: "/account_details",
    style: "col-lg-4",
  },
  {
    label: "EMAIL",
    name: "email",
    navigate: true,
    path: "/account_details",
    verified: true,
    verifiedKey: "emailVerified",
  },
  {
    label: "PHONE",
    name: "phoneNumber",
    verified: true,
    verifiedKey: "phoneNumberVerified",
  },
  {
    label: "LOCATION",
    name: "address",
    style: "col-lg-12",
  },
];

export const deleteOption = [
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
];
export const issueOption = [
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
];
export const refundOption = [
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
];

export const editSubscriptonPrice = ["$26.95", "$26.95", "$26.95"];
export const editSubscriptonDiscount = ["0%", "1%", "2%"];

export const privateAccountBasicDetailsColumn = [
  {
    label: "Name",
    name: "fullName",
  },
  {
    label: "Gender",
    name: "gender",
  },
  {
    label: "Email",
    name: "email",
    verified: true,
    verifiedKey: "emailVerified",
  },
  {
    label: "Phone",
    name: "phoneNumber",
    verified: true,
    verifiedKey: "phoneNumberVerified",
  },
  {
    label: "City",
    name: "city",
  },
  {
    label: "Address",
    name: "address",
  },
  {
    label: "Date of Birth",
    name: "dateOfBirth",
  },
  // {
  //     label: "Current plan",
  //     name: "current_plan"
  // }
];

export const dealerAccountBasicDetailsColumn = [
  {
    label: "Name",
    name: "fullName",
  },
  {
    label: "Gender",
    name: "gender",
  },
  {
    label: "Email",
    name: "email",
    verified: true,
    verifiedKey: "emailVerified",
  },
  {
    label: "Phone",
    name: "phoneNumber",
    verified: true,
    verifiedKey: "phoneNumberVerified",
  },
  {
    label: "City",
    name: "city",
  },
  {
    label: "Address",
    name: "address",
  },
  {
    label: "Company name",
    name: "dealerShipName",
  },
  {
    label: "Date of Birth",
    name: "dateOfBirth",
  },
  {
    label: "Website",
    name: "website",
    visit: true,
  },
  // {
  //     label: "Current plan",
  //     name: "current_plan"
  // },
  {
    label: "About",
    name: "about",
    style: "col-12 mb-4",
  },
];

export const advertsPrivateAccountColumn = [
  {
    label: "ITEM",
    name: "item",
    navigate: true,
    path: "/view_advert",
  },
  {
    label: "DATE POSTED",
    name: "date",
  },
  {
    label: "IMPRESSION",
    name: "imppression",
  },
  {
    label: "STATUS",
    name: "status",
  },
];

export const advertsDealerAccountColumn = [
  {
    label: "ITEM",
    name: "item",
    navigate: true,
    path: "/view_advert",
  },
  {
    label: "DATE POSTED",
    name: "date",
  },
  {
    label: "IMPRESSION",
    name: "imppression",
  },
  {
    label: "STATUS",
    name: "status",
  },
];

export const transactionDealerAccountColumn = [
  {
    label: "ID",
    name: "id",
  },
  {
    label: "DATE",
    name: "date",
  },
  {
    label: "ITEM",
    name: "item",
  },
  {
    label: "AMOUNT",
    name: "amount",
  },
  {
    label: "STATUS",
    name: "status",
  },
];

export const transactionPrivateAccountColumn = [
  {
    label: "ID",
    name: "id",
  },
  {
    label: "DATE",
    name: "date",
  },
  {
    label: "ITEM",
    name: "item",
  },
  {
    label: "AMOUNT",
    name: "amount",
  },
  {
    label: "STATUS",
    name: "status",
  },
];

export const bannerImagesColumn = [
  {
    label: "PAGE NAME",
    name: "page_name",
  },
  {
    label: "LAST UPDATED",
    name: "last_updated",
  },
  {
    label: "CURRENT BANNER",
    name: "current_banner",
    type: "image",
    style: "w-100",
  },
];

export const colorsTableColoumns = [
  {
    label: "English",
    name: "name_en",
  },
  {
    label: "Arabic",
    name: "name_ar",
  },
  {
    label: "Kurdish",
    name: "name_ku",
  },
];
export const makeYearTableColoumns = [
  {
    label: "Year",
    name: "value",
  },
];
export const featuresTableColoumns = [
  {
    label: "English",
    name: "name_en",
  },
  {
    label: "Arabic",
    name: "name_ar",
  },
  {
    label: "Kurdish",
    name: "name_ku",
  },
];
export const makesTableColoumns = [
  {
    label: "Logo",
    name: "logo",
  },
  {
    label: "Make Name",
    name: "name_en",
  },
  // {
  //   label: 'Kurdish',
  //   name: "name_ku"
  // },
];

export const reasonsToDelist = [
  { name: "Violation of Terms of Service" },
  { name: "Inappropriate Content" },
  { name: "Impersonation" },
  { name: "Abusive Behavior" },
  { name: "Illegal Activities" },
  { name: "Misuse of Intellectual Property" },
  { name: "Non-Compliance with Community Guidelines" },
];

export const cities = [
  {
    _id: 1,
    name: { en: "Abu Al-Khaseeb", ar: "Abu Al-Khaseeb", ku: "Abu Al-Khaseeb" },
  },
  { _id: 2, name: { en: "Akre", ar: "Akre", ku: "Akre" } },
  { _id: 3, name: { en: "Al Khalis", ar: "Al Khalis", ku: "Al Khalis" } },
  {
    _id: 4,
    name: { en: "Al Midhatiya", ar: "Al Midhatiya", ku: "Al Midhatiya" },
  },
  {
    _id: 5,
    name: {
      en: "Al Nasr Wal Salam",
      ar: "Al Nasr Wal Salam",
      ku: "Al Nasr Wal Salam",
    },
  },
  { _id: 6, name: { en: "Al Qa'im", ar: "Al Qa'im", ku: "Al Qa'im" } },
  { _id: 7, name: { en: "Al Qasim", ar: "Al Qasim", ku: "Al Qasim" } },
  { _id: 8, name: { en: "Al Rifa'i", ar: "Al Rifa'i", ku: "Al Rifa'i" } },
  { _id: 9, name: { en: "Al Rumaitha", ar: "Al Rumaitha", ku: "Al Rumaitha" } },
  { _id: 10, name: { en: "Al Shamal", ar: "Al Shamal", ku: "Al Shamal" } },
  { _id: 11, name: { en: "Al Shamiya", ar: "Al Shamiya", ku: "Al Shamiya" } },
  { _id: 12, name: { en: "Al Shatrah", ar: "Al Shatrah", ku: "Al Shatrah" } },
  { _id: 13, name: { en: "Al Suwaira", ar: "Al Suwaira", ku: "Al Suwaira" } },
  { _id: 14, name: { en: "Al Wahda", ar: "Al Wahda", ku: "Al Wahda" } },
  {
    _id: 15,
    name: { en: "Al-Aziziyah", ar: "Al-Aziziyah", ku: "Al-Aziziyah" },
  },
  { _id: 16, name: { en: "Al Hindiya", ar: "Al Hindiya", ku: "Al Hindiya" } },
  {
    _id: 17,
    name: { en: "Al Mahmudiyah", ar: "Al Mahmudiyah", ku: "Al Mahmudiyah" },
  },
  {
    _id: 18,
    name: {
      en: "Al Mejar Al Kabir",
      ar: "Al Mejar Al Kabir",
      ku: "Al Mejar Al Kabir",
    },
  },
  {
    _id: 19,
    name: { en: "An Numaniyah", ar: "An Numaniyah", ku: "An Numaniyah" },
  },
  { _id: 20, name: { en: "Amarah", ar: "Amarah", ku: "Amarah" } },
  { _id: 21, name: { en: "Baiji", ar: "Baiji", ku: "Baiji" } },
  { _id: 22, name: { en: "Baghdad", ar: "Baghdad", ku: "Baghdad" } },
  { _id: 23, name: { en: "Baqubah", ar: "Baqubah", ku: "Baqubah" } },
  { _id: 24, name: { en: "Balad", ar: "Balad", ku: "Balad" } },
  { _id: 25, name: { en: "Balad Ruz", ar: "Balad Ruz", ku: "Balad Ruz" } },
  { _id: 26, name: { en: "Banslawa", ar: "Banslawa", ku: "Banslawa" } },
  { _id: 27, name: { en: "Basra", ar: "Basra", ku: "Basra" } },
  { _id: 28, name: { en: "Bekreco", ar: "Bekreco", ku: "Bekreco" } },
  { _id: 29, name: { en: "Chamchamal", ar: "Chamchamal", ku: "Chamchamal" } },
  { _id: 30, name: { en: "Daretu", ar: "Daretu", ku: "Daretu" } },
  { _id: 31, name: { en: "Diwaniyah", ar: "Diwaniyah", ku: "Diwaniyah" } },
  { _id: 32, name: { en: "Diyala", ar: "Diyala", ku: "Diyala" } },
  { _id: 33, name: { en: "Dohuk", ar: "Dohuk", ku: "Dohuk" } },
  { _id: 34, name: { en: "Fallujah", ar: "Fallujah", ku: "Fallujah" } },
  { _id: 35, name: { en: "Fayda", ar: "Fayda", ku: "Fayda" } },
  { _id: 36, name: { en: "Haji Awa", ar: "Haji Awa", ku: "Haji Awa" } },
  { _id: 37, name: { en: "Halabja", ar: "Halabja", ku: "Halabja" } },
  { _id: 38, name: { en: "Hawija", ar: "Hawija", ku: "Hawija" } },
  { _id: 39, name: { en: "Hillah", ar: "Hillah", ku: "Hillah" } },
  { _id: 40, name: { en: "Hit", ar: "Hit", ku: "Hit" } },
  {
    _id: 41,
    name: { en: "Iskandariya", ar: "Iskandariya", ku: "Iskandariya" },
  },
  { _id: 42, name: { en: "Jalawla", ar: "Jalawla", ku: "Jalawla" } },
  { _id: 43, name: { en: "Karbala", ar: "Karbala", ku: "Karbala" } },
  { _id: 44, name: { en: "Kasnazan", ar: "Kasnazan", ku: "Kasnazan" } },
  { _id: 45, name: { en: "Kalar", ar: "Kalar", ku: "Kalar" } },
  { _id: 46, name: { en: "Khanakin", ar: "Khanakin", ku: "Khanakin" } },
  { _id: 47, name: { en: "Kirkuk", ar: "Kirkuk", ku: "Kirkuk" } },
  { _id: 48, name: { en: "Koy Sanjaq", ar: "Koy Sanjaq", ku: "Koy Sanjaq" } },
  { _id: 49, name: { en: "Kufa", ar: "Kufa", ku: "Kufa" } },
  { _id: 50, name: { en: "Kut", ar: "Kut", ku: "Kut" } },
  { _id: 51, name: { en: "Mosul", ar: "Mosul", ku: "Mosul" } },
  { _id: 52, name: { en: "Musayyib", ar: "Musayyib", ku: "Musayyib" } },
  { _id: 53, name: { en: "Najaf", ar: "Najaf", ku: "Najaf" } },
  { _id: 54, name: { en: "Nasiriyah", ar: "Nasiriyah", ku: "Nasiriyah" } },
  { _id: 55, name: { en: "Numaniyah", ar: "Numaniyah", ku: "Numaniyah" } },
  { _id: 56, name: { en: "Qahtaniyah", ar: "Qahtaniyah", ku: "Qahtaniyah" } },
  { _id: 57, name: { en: "Qaladiza", ar: "Qaladiza", ku: "Qaladiza" } },
  {
    _id: 58,
    name: { en: "Qalat Sukkar", ar: "Qalat Sukkar", ku: "Qalat Sukkar" },
  },
  { _id: 59, name: { en: "Qasim", ar: "Qasim", ku: "Qasim" } },
  { _id: 60, name: { en: "Qasrok", ar: "Qasrok", ku: "Qasrok" } },
  { _id: 61, name: { en: "Ramadi", ar: "Ramadi", ku: "Ramadi" } },
  { _id: 62, name: { en: "Ranya", ar: "Ranya", ku: "Ranya" } },
  { _id: 63, name: { en: "Said Sadiq", ar: "Said Sadiq", ku: "Said Sadiq" } },
  { _id: 64, name: { en: "Samarra", ar: "Samarra", ku: "Samarra" } },
  { _id: 65, name: { en: "Samawah", ar: "Samawah", ku: "Samawah" } },
  { _id: 66, name: { en: "Shamiyah", ar: "Shamiyah", ku: "Shamiyah" } },
  {
    _id: 67,
    name: { en: "Shatt Al Arab", ar: "Shatt Al Arab", ku: "Shatt Al Arab" },
  },
  { _id: 68, name: { en: "Shirqat", ar: "Shirqat", ku: "Shirqat" } },
  { _id: 69, name: { en: "Shorish", ar: "Shorish", ku: "Shorish" } },
  { _id: 70, name: { en: "Simele", ar: "Simele", ku: "Simele" } },
  { _id: 71, name: { en: "Sinjar", ar: "Sinjar", ku: "Sinjar" } },
  {
    _id: 72,
    name: { en: "Sulaymaniyah", ar: "Sulaymaniyah", ku: "Sulaymaniyah" },
  },
  {
    _id: 73,
    name: { en: "Suq al-Shuyukh", ar: "Suq al-Shuyukh", ku: "Suq al-Shuyukh" },
  },
  { _id: 74, name: { en: "Soran", ar: "Soran", ku: "Soran" } },
  { _id: 75, name: { en: "Tal Afar", ar: "Tal Afar", ku: "Tal Afar" } },
  { _id: 76, name: { en: "Tikrit", ar: "Tikrit", ku: "Tikrit" } },
  {
    _id: 77,
    name: { en: "Tuz Khurmatu", ar: "Tuz Khurmatu", ku: "Tuz Khurmatu" },
  },
  { _id: 78, name: { en: "Umm Qasr", ar: "Umm Qasr", ku: "Umm Qasr" } },
  { _id: 79, name: { en: "Zubayr", ar: "Zubayr", ku: "Zubayr" } },
];

export const langs = [
  {
    id: 1,
    label: "English",
    value: "en",
  },
  {
    id: 2,
    label: "Arabic",
    value: "ar",
  },
  {
    id: 3,
    label: "Kurdish",
    value: "ku",
  },
];

import { UncontrolledCarousel } from "reactstrap";

const KycModel = ({ heading, row }) => {
  console.log(row);
  const images = row?.additionalInfo?.dealerKycDocuments;
  console.log("images", images);
  return (
    // <div
    //   id="carouselExampleIndicators"
    //   class="carousel slide"
    //   data-bs-ride="carousel"
    // >
    //   <div class="carousel-inner">
    //     <div class="carousel-item active">
    //       <img src="../assets/images/docsingle.png" class="w-100" alt="..." />
    //     </div>
    //     <div class="carousel-item">
    //       <img src="../assets/images/docsingle.png" class="w-100" alt="..." />
    //     </div>
    //     <div class="carousel-item">
    //       <img src="../assets/images/docsingle.png" class="w-100" alt="..." />
    //     </div>
    //     {images?.map((item, i) => {
    //       return (
    //         <div class={`carousel-item ${i==0 ? 'active' : ''}`} key={i}>
    //           <img src={item} class="w-100" alt="..." />
    //         </div>
    //       );
    //     })}
    //   </div>
    //   <ul class="carousel-indicators">
    //     {/* <li
    //       data-bs-target="#carouselExampleIndicators"
    //       data-bs-slide-to="0"
    //       class="active"
    //       aria-current="true"
    //       aria-label="Slide 1"
    //     >
    //       {" "}
    //       <img src="../assets/images/docsingle.png" alt="..." />
    //     </li>
    //     <li
    //       data-bs-target="#carouselExampleIndicators"
    //       data-bs-slide-to="1"
    //       aria-label="Slide 2"
    //     >
    //       <img src="../assets/images/docsingle.png" alt="..." />
    //     </li>
    //     <li
    //       data-bs-target="#carouselExampleIndicators"
    //       data-bs-slide-to="2"
    //       aria-label="Slide 3"
    //     >
    //       <img src="../assets/images/docsingle.png" alt="..." />
    //     </li> */}
    //     {images?.map((item, i) => {
    //       return (
    //         <li
    //           data-bs-target="#carouselExampleIndicators"
    //           data-bs-slide-to={i}
    //           aria-label={`Slide ${i+1}`}
    //         >
    //           <img src={item} alt="..." />
    //         </li>
    //       );
    //     })}
    //   </ul>
    // </div>
    <div>
      <UncontrolledCarousel
        dark
        autoPlay={false}
        style={{ height: "100%", width: "100%", overflow: 'hidden' }}
        items={images.map((v, i) => {
          return {
            altText: "Slide " + (i + 1),
            // caption: "Slide " + (i + 1),
            key: i + 1,
            src: v,
          };
        })}
      />
    </div>
  );
};
export default KycModel;

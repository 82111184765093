import ChecboxWithSpan from "../../Tools/CheckboxWithSpan";
const SendNotificationOption = ({ allFilter, setAllFilter }) => {
  const options = [
    { label: "To App", value: "app" },
    { label: "To Phone", value: "phone" },
    { label: "To Email", value: "email" },
  ];
  return (
    <p className="d-flex justify-content-start">
      {/* <ChecboxWithSpan
        parentStyle={"checkcontainer"}
        childStyle={"radiobtn"}
        inType={"checkbox"}
        inputStyle={"quality"}
        name={"quality"}
        value={"T1"}
        title={"To App"}
      />
      <ChecboxWithSpan
        parentStyle={"checkcontainer"}
        childStyle={"radiobtn"}
        inType={"checkbox"}
        inputStyle={"quality"}
        name={"phone"}
        value={"T1"}
        title={"To Phone"}
      />
      <ChecboxWithSpan
        inStyle={{ marginRight: "0px" }}
        parentStyle={"checkcontainer"}
        childStyle={"radiobtn"}
        inType={"checkbox"}
        inputStyle={"quality"}
        name={"email"}
        value={"T1"}
        title={"To Email"}
      /> */}
      {options.map((item, i) => {
        return (
          <label
            htmlFor={`sendOnInput${i}`}
            key={i}
            style={{ fontSize: "15px" }}
            className="ms-2"
          >
            <input
              id={`sendOnInput${i}`}
              type="radio"
              checked={allFilter.sendOn == item?.value}
              value={item?.value}
              className="me-1"
              onClick={(e) =>
                // console.log(e.target.value)
                setAllFilter({ ...allFilter, sendOn: e.target.value })
              }
            />
            {item?.label}
          </label>
        );
      })}
    </p>
  );
};

export default SendNotificationOption;

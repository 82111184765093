import { combineReducers } from '@reduxjs/toolkit';
import userSlice from './Reducer/user/userSlice';
import dashboardSlice from './Reducer/dashboard/dashboardSlice';
import pagesSlice from './Reducer/pages/pagesSlice';
import blockWordsSlice from './Reducer/blockWords/blockWordsSlice';
import singlePendingApprovalSlice from './Reducer/user/singlePendingApprovalSlice';
import editpageSlice from './Reducer/pages/editpageSlice';
import userAccountSlice from './Reducer/userAccount/userAccountSlice';
import pricingSlice from './Reducer/pricing/pricingSlice';
import accountDetailsSlice from './Reducer/userAccount/accountDetailsSlice';
import sendNotificationSlice from './Reducer/sendNotification/sendNotificationSlice';
import bannerImagesSlice from './Reducer/bannerImages/bannerImagesSlice';
import profileSilce from './Reducer/profileSilce/profileSilce';
import authSlice from './Reducer/auth/authSlice';

const rootReducer = combineReducers({
    user:userSlice,
    dashboard: dashboardSlice,
    pages: pagesSlice,
    blockWords: blockWordsSlice,
    singlePendingApproval: singlePendingApprovalSlice,
    editPage: editpageSlice,
    userAccountList: userAccountSlice,
    pricing: pricingSlice,
    accountDetails: accountDetailsSlice,
    sendNotificationusers: sendNotificationSlice,
    bannerImages: bannerImagesSlice,
    profile:profileSilce,
    auth:authSlice
});

export default rootReducer;
import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

const Skeletons2 = ({ lines }) => {
  return (
    <Box>
      {Array(lines)
        .fill()
        .map((item, i) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ mx: 2, width: "100%" }}>
                <Typography sx={{ fontWeight: 500, fontSize: 40 }}>
                  <Skeleton variant="text" sx={{ width: "100%" }}></Skeleton>
                </Typography>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default Skeletons2;

import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInputs from "../../Tools/FormInput/Newforminput";
import swal from "sweetalert";
import { editProfile, uploadToStorage } from "../../../services/api";
export default function EditProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const meta = location.state.data;
  const [loading, setLoading] = useState(false);
  const [profiledata, setprofile] = useState(meta);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(file);
      };

      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };
  const schema = Yup.object().shape({
    fullName: Yup.string().required("Full name  is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    email: Yup.string().required("Email is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: profiledata?.fullName,
      phoneNumber: profiledata?.phoneNumber,
      email: profiledata?.email,
    },
  });

  const onError = (errors) => {
    console.log(errors);
  };
  const onSubmitform = async (data) => {
    if (loading) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("files", selectedImage);
    if (selectedImage) {
      uploadToStorage(formData, 3).then((res) => {
        if (res?.status) {
          data["profilePicture"] = res.data[0].s3URL;
          handleSubmitEditProfile(data);
        }
      });
    } else {
      handleSubmitEditProfile(data);
    }
  };
  const handleSubmitEditProfile = (data) => {
    editProfile(data).then((res) => {
      if (res?.status) {
        swal("Success", res?.message, "success");
        navigate("/profile");
      } else {
        swal("Error", res?.message, "error");
      }
      setLoading(false);
    });
  };
  document.title = "Edit Profile";
  return (
    <div className="overview">
      {profiledata ? (
        <>
          <h3 class="pt-0">
            {"Edit Basic Details"}{" "}
            <span class="saveCancel d-none d-lg-inline">
              <NavLink to="/profile">{"Cancel"}</NavLink>
              <button
                class="save"
                type="button"
                disabled={loading}
                onClick={handleSubmit(onSubmitform, onError)}
              >
                {loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Save"
                )}
              </button>
            </span>
          </h3>

          <div class="my_profile">
            <form>
              <div class="row">
                <div class="col-sm-3 col-xl-2">
                  <div class="imgs border" style={{ position: "relative" }}>
                    <input
                      type="file"
                      id="fileInput"
                      onClick={e => e.target.value = null}
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    <img
                      class="p-2 rounded-3"
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : (profiledata.profilePicture !== "N/A" &&
                              profiledata.profilePicture) ||
                            "/assets/images/ap2.png"
                      }
                      height={150}
                      // width={100}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        objectPosition: "top",
                      }}
                      align="img"
                    />
                    {selectedImage ? (
                      <span
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          right: "5px",
                          padding: "0px 6px",
                        }}
                        className="bg-light rounded-1"
                      >
                        <img
                          src="../assets/images/x-circle.png"
                          alt="icon"
                          style={{ width: "18px" }}
                          onClick={() => {
                            setSelectedImage("");
                          }}
                        />
                      </span>
                    ) : (
                      <span
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          right: "5px",
                          padding: "0px 6px",
                        }}
                        className="bg-light rounded-1"
                      >
                        <img
                          src="../assets/images/cmra.png"
                          alt="icon"
                          style={{ width: "18px" }}
                          onClick={() => {
                            document.getElementById("fileInput").click();
                          }}
                        />
                      </span>
                    )}
                  </div>
                </div>
                <div class="col-sm-9 col-xl-10">
                  <div class="row">
                    <div class="col-sm-6 col-xl-4 ">
                      <h6>{"Full name"}</h6>

                      <FormInputs
                        style={"form-control"}
                        intype={"text"}
                        event={register}
                        // value={profiledata.fullName}
                        name={"fullName"}
                        placeholder={"Type full name"}
                      />
                      {errors.fullName && (
                        <p className="helperText">{errors.fullName.message}</p>
                      )}
                    </div>

                    <div class="col-sm-6 col-xl-4 ">
                      <h6>{"Phone"}</h6>

                      <FormInputs
                        style={"form-control"}
                        intype={"number"}
                        event={register}
                        // value={profiledata.phoneNumber}
                        name={"phoneNumber"}
                        placeholder={"Type phone number"}
                      />
                      {errors.phoneNumber && (
                        <p className="helperText">
                          {errors.phoneNumber.message}
                        </p>
                      )}
                    </div>
                    <div class="col-sm-6 col-xl-4 ">
                      <h6>{"Email"}</h6>

                      <FormInputs
                        style={"form-control"}
                        intype={"email"}
                        event={register}
                        // value={profiledata.email}
                        name={"email"}
                        placeholder={"Type email"}
                      />
                      {errors.email && (
                        <p className="helperText">{errors.email.message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col-12 d-block d-lg-none">
                  <span
                    class="saveCancel row w-100"
                    style={{
                      marginTop: "0px",
                      marginLeft: "0px",
                      float: "unset",
                    }}
                  >
                    <button
                      className="save w-100 my-2"
                      type="button"
                      onClick={handleSubmit(onSubmitform, onError)}
                      style={{ margin: "0px 0px 0px 0px" }}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <div
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </>
                      ) : (
                        "Save"
                      )}
                    </button>
                    <NavLink
                      className="w-100 my-2"
                      to="/profile"
                      style={{ margin: "0px 0px 0px 0px" }}
                    >
                      {"Cancel"}
                    </NavLink>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Crousel from "../../Tools/Crousel";
import ListItem from "../../Tools/ListItem";
import CarDetailModel from "../../Tools/CarDetailModel";
import swal from "sweetalert";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import CarDetailModel from "../../Tools/CarDetailModel";

import { Button } from "reactstrap";
import SinglePageRight from "./singlePageRight";
import { formatPrice, numberWithCommas } from "../../../data/utils";
import { apiManager } from "../../../data/apiHandler";
import BoostrapModel from "../../Tools/BoostrapModel";
import Confirm from "../singlePendingApprovalUser/confirm";
import { sendEmail, sendWhatsAppMessage } from "../../../services/api";
import { adMsgs } from "../../../data/translations";

export default function SingleCar() {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [pageloading, setPageLoading] = useState(false);
  const [params] = useSearchParams();
  const adId = params.get("id");
  const sold = params.get("sold");
  const approval = params.get("approving") === "true" ? true : false;
  const [page, setPage] = useState("normal");

  //approval modal states
  const [selectedFile, setSelectedFile] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const openModal = (data) => {
    setModalData(data);
    setModal(true);
  };
  const handleCloseModal = () => {
    setModal(false);
  };
  const handleApprove = (data) => {
    const url =
      data.type === "approve"
        ? `admin/ad/approve?lang=${"en"}&adId=${adId}`
        : `admin/ad/reject?lang=${"en"}&adId=${adId}`;
    apiManager("PUT", url).then((res) => {
      if (res?.status) {
        let template = adMsgs?.[data?.type]?.[data?.lang]
          .replace("{{username}}", modalData?.rawData?.owner?.fullName)
          .replace("{{message}}", data?.body);

        //     sendEmail({
        //       subject: data?.title,
        //       message: data?.body,
        //       userIds: [modalData?.rawData?.owner?._id],
        //       type: "0",
        //       attachments: selectedFile.map((v) => v?.s3URL),
        //     });

        sendWhatsAppMessage({
          phoneNumber: modalData?.rawData?.owner?.phoneNumber,
          message: template,
        }, data?.lang);

        swal("Success", res?.message, "success");
        setModal(false);
        navigate(-1);
      }
    });
  };
  const approveWithoutMail = (data) => {
    console.log("withut mail", modalData?.type);
    const url =
      modalData.type === "approve"
        ? `admin/ad/approve?lang=${"en"}&adId=${adId}`
        : `admin/ad/reject?lang=${"en"}&adId=${adId}`;
    apiManager("PUT", url).then((res) => {
      if (res?.status) {
        swal("Success", res?.message, "success");
        setModal(false);
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    setPageLoading(true);
    apiManager(
      "GET",
      `opp/filteredAds?lang=${"en"}&adId=${adId}${sold ? "&sold=YES" : ""}`
    )
      .then((res) => {
        if (res.status) {
          const dataT = res?.data?.ads?.[0];
          setData(dataT);
          setPage(dataT?.adTypeName?.en === "Sell" ? "normal" : "rental");
        } else {
          console.log(res);
        }
        setPageLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  }, [params.get("adId")]);

  const [day, setDay] = useState(page);

  const handleChange = (e) => {
    setRadio(e.target.value);
    setDay(e.target.value);
  };

  const all_images = data?.uploads;

  const getPrice = () => {
    switch (day) {
      case "normal":
        return `${formatPrice("en", data?.askPrice)}`;
      case "daily":
        return `${formatPrice("en", data?.askPrice)}/${"Daily"}`;
      case "weekly":
        return `${formatPrice("en", data?.askPrice1)}/${"Weekly"}`;
      case "monthly":
        return `${formatPrice("en", data?.askPrice2)}/${"Monthly"}`;
      case "rental":
        return `${formatPrice("en", data?.askPrice)}/${"Daily"}`;
      default:
        return "ali ali";
    }
  };

  const [radioState, setRadio] = useState("daily");

  const [readMore, setReadMore] = useState(false);

  const [chatNow, setChatNow] = useState({
    loading: false,
    adId: "",
  });

  return pageloading ? (
    <div
      class="d-flex justify-content-center"
      style={{ minHeight: "100vh", marginTop: "25vh" }}
    >
      <div class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      <section
        class="singlepage"
        style={{ paddingTop: "0px", borderTop: "0px" }}
      >
        <div class="container">
          <h6 class="back d-flex justify-content-between">
            <a onClick={() => navigate(-1)}>
              <img src="../assets/images/backbt.png" /> {"Go back"}
            </a>
          </h6>
          <div className="row d-flex justify-content-end mb-2">
            <div className="col-sm-4 col-lg-3 col-xl-2 my-2">
              <a
                className="w-100 themeButton d-flex justify-content-center align-items-center"
                style={{ marginLeft: "0px", textDecoration: "none" }}
                onClick={() => navigate(`/edit_advert?id=${adId}`)}
              >
                Edit Advert
              </a>
            </div>
            {approval && (
              <>
                <div className="col-sm-4 col-lg-3 col-xl-2 my-2">
                  <a
                    className="w-100 themeButtonNo d-flex justify-content-center align-items-center"
                    style={{ marginLeft: "0px", textDecoration: "none" }}
                    onClick={() =>
                      openModal({
                        type: "reject",
                        key: "Reject Advert..!?",
                        rawData: data,
                      })
                    }
                  >
                    Reject Advert
                  </a>
                </div>
                <div className="col-sm-4 col-lg-3 col-xl-2 my-2">
                  <a
                    className="w-100 themeButtonYes d-flex justify-content-center align-items-center"
                    style={{ marginLeft: "0px", textDecoration: "none" }}
                    onClick={() =>
                      openModal({
                        type: "approve",
                        key: "Approve Advert..!?",
                        rawData: data,
                      })
                    }
                  >
                    Approve Advert
                  </a>
                </div>
              </>
            )}
          </div>
          <div class="row">
            <div className="col-lg-12">
              <Crousel
                data={
                  data?.uploads?.length > 0
                    ? data?.uploads?.map((v) => v.s3URL)
                    : []
                }
              />
            </div>
            <div className="col-lg-12">
              <SinglePageRight
                classNames={"singlepage_right d-sm-block d-none"}
                page={page}
                data={data}
                radioState={radioState}
                handleChange={handleChange}
                getPrice={getPrice}
                lang={"en"}
                params={params}
                //   handleInitiateChat={handleInitiateChat}
                chatNow={chatNow}
                setChatNow={setChatNow}
              />
            </div>
            <div class="col-lg-12">
              <div
                class="singlepage_left"
                style={{ position: "relative", overflow: "hidden" }}
              >
                <SinglePageRight
                  classNames={"singlepage_right d-sm-none d-block"}
                  page={page}
                  data={data}
                  radioState={radioState}
                  handleChange={handleChange}
                  getPrice={getPrice}
                  lang={"en"}
                  params={params}
                  // handleInitiateChat={handleInitiateChat}
                  chatNow={chatNow}
                  setChatNow={setChatNow}
                />

                <h5 style={{ position: "unset" }}>
                  {"Car Details"}
                  {/* <ul
                    class="butm d-flex saveShare"
                    style={{ ["en" === "en" ? "right" : "left"]: "0px" }}
                  >
                    <Button
                      id={`PopoverFocus${data?._id}`}
                      type="button"
                      onClick={() => setSharePop(true)}
                      className="btn-sm btn-light"
                      style={{
                        backgroundColor: "transparent",
                        border: "unset",
                        padding: "0px",
                      }}
                    >
                      <li>
                        <img
                          src="../assets/images/ser1.png"
                          alt="img"
                          style={{ marginTop: "-1px" }}
                        />{" "}
                        <span className="d-none d-sm-inline">{"Share"}</span>
                      </li>
                    </Button>
                    <ShareModal
                        data={data}
                        open={sharePop}
                        close={handleCloseSharePop}
                      />
                    {saveLoad ? (
                        <span className="w-100 d-flex justify-content-center loader">
                          <button
                            class="btn btn-light"
                            type="button"
                            disabled
                            style={{ padding: "0px" }}
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden" role="status">
                              Loading...
                            </span>
                          </button>
                        </span>
                      ) : (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => toggleSaveCar()}
                        >
                          <img
                            src={`assets/images/${savedIcon}`}
                            alt="img"
                            style={{ marginTop: "-1px" }}
                          />{" "}
                          <span className="d-none d-sm-inline">
                            {savedCars?.includes(data?._id)
                              ? ("heartSaved")
                              : ("Save")}
                          </span>
                        </li>
                      )}
                  </ul> */}
                </h5>
                {data?.properties?.specifications?.km ? (
                  <h6>
                    <img src="../assets/images/adt.png" align="icon" />{" "}
                    {`${
                      numberWithCommas(data?.properties?.specifications?.km) +
                      " " +
                      "KMs"
                    }`}
                  </h6>
                ) : (
                  <></>
                )}
                <ul class="hatchback">
                  <ListItem
                    key={0}
                    innerhtmlType={"html"}
                    value={
                      <>
                        <img src={"../assets/images/mil1.png"} align="icon" />
                        <span
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {data?.properties?.specifications?.bodyType?.["en"]}
                        </span>
                      </>
                    }
                  />
                  <ListItem
                    key={0}
                    innerhtmlType={"html"}
                    value={
                      <>
                        <img src={"../assets/images/mil2.png"} align="icon" />
                        <span
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {data?.properties?.specifications?.engineSize
                            ? data?.properties?.specifications?.engineSize
                            : "--"}
                        </span>
                      </>
                    }
                  />
                  <ListItem
                    key={0}
                    innerhtmlType={"html"}
                    value={
                      <>
                        <img src={"../assets/images/mil3.png"} align="icon" />
                        <span
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {data?.properties?.specifications?.gearbox?.["en"]}
                        </span>
                      </>
                    }
                  />
                  <ListItem
                    key={0}
                    innerhtmlType={"html"}
                    value={
                      <>
                        <img src={"../assets/images/mil5.png"} align="icon" />
                        <span
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {data?.properties?.specifications?.fuelType?.["en"]}
                        </span>
                      </>
                    }
                  />
                  <ListItem
                    key={0}
                    innerhtmlType={"html"}
                    value={
                      <>
                        <img src={"../assets/images/mil4.png"} align="icon" />
                        <span
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {data?.properties?.specifications?.doors} {"Doors"}
                        </span>
                      </>
                    }
                  />
                  <ListItem
                    key={0}
                    innerhtmlType={"html"}
                    value={
                      <>
                        <img src={"../assets/images/mil6.png"} align="icon" />
                        <span
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {data?.properties?.specifications?.seats} {"Seats"}
                        </span>
                      </>
                    }
                  />
                </ul>
                {data?.properties?.features?.carDescription?.["en"] &&
                data?.properties?.features?.carDescription?.["en"]?.charAt(
                  0
                ) !== "[" ? (
                  <>
                    <h5 class="mb-3">{"Description"}</h5>
                    <p
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: readMore ? 100 : 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        // idth: "350px",
                      }}
                    >
                      {data?.properties?.features?.carDescription?.["en"]
                        ? data?.properties?.features?.carDescription?.["en"]
                        : "--"}
                    </p>
                    {data?.properties?.features?.carDescription?.["en"]
                      ?.length > 190 && (
                      <a
                        className="mb-3"
                        onClick={() => {
                          setReadMore(!readMore);
                        }}
                      >
                        {readMore ? "See less" : "See more"}
                      </a>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {data?.properties?.features?.carDescription?.["en"]?.length >
                  190 && (
                  <a
                    className="mb-3"
                    onClick={() => {
                      setReadMore(!readMore);
                    }}
                  >
                    {readMore ? "See less" : "See more"}
                  </a>
                )}
                <h5
                  class="spe"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {"Specifications"}{" "}
                  <img src="../assets/images/aron.png" align="aro" />
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      {data?.properties && (
        <CarDetailModel
          data={data?.properties ? data?.properties : []}
          title={"Specifications"}
        />
      )}

      <BoostrapModel
        show={modal}
        heading={modalData?.key}
        style={"warning warningKyc "}
        fullWidth
        component={
          <Confirm
            data={modalData}
            handleSubmit={handleApprove}
            closeModal={handleCloseModal}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            setStatusWithoutMail={approveWithoutMail}
          />
        }
        onHide={() => setModal(false)}
      />
    </>
  );
}

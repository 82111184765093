export const tr = {
  en: {
    "Write your text...": "Write your text...",
  },
  ar: {
    "Write your text...": "أكتب النص الخاص بك...",
  },
  ku: {
    "Write your text...": "دەقەکەت بنووسە...",
  },
};

export const kycMsgs = {
  approve: {
    en: "Hello {{username}},\nYour KYC has been approved.\n{{message}}",
    ar: 'مرحبا {{username}}،\nتمت الموافقة على ملفات "اعرف عميلك" (KYC) الخاص بك..\n{{message}}',
    ku: 'سڵاو {{username}}،\nفایلەکانی "کڕیارەکەت بناسە" (KYC) پەسەند کراون.\n{{message}}',
  },
  reject: {
    en: "Hello {{username}},\nYour KYC has been rejected.\n{{message}}",
    ar: 'مرحبا {{username}}،\nلقد تم رفض ملفات "اعرف عميلك" (KYC) الخاص بك..\n{{message}}',
    ku: 'سڵاو {{username}}،\nفایلەکانی "کڕیارەکەت بناسە" (KYC) ڕەتکراونەتەوە.\n{{message}}',
  },
};

export const adMsgs = {
  approve: {
    en: "Hello {{username}},\nYour Advert has been approved and posted on Ako Sayara.\n{{message}}",
    ar: "مرحبا {{username}}،\nلقد تمت الموافقة على إعلانك وتم نشرهُ على اكو سيارة.\n{{message}}",
    ku: "سڵاو {{username}}،\nڕیکلامەکەت پەسەند کراوە و لە ئەکو سەیارە بڵاوکراوەتەوە.\n{{message}}",
  },
  reject: {
    en: "Hello {{username}},\nYour Advert has been rejected on Ako Sayara.\n{{message}}",
    ar: "مرحبا {{username}}،\nتم رفض إعلانك على اكو سيارة.\n{{message}}",
    ku: "سڵاو {{username}}،\nڕیکلامەکەت لەسەر ئەکو سەیارە ڕەتکرایەوە.\n{{message}}",
  },
};

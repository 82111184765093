import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

const Skeletons1 = ({ lines }) => {
  return (
    <Box>
      {Array(lines)
        .fill()
        .map((item, i) => {
          return (
            <Grid container marginBottom={8} spacing={2} key={i}>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Box sx={{ mx: 2, width: "100%" }}>
                      <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                        <Skeleton
                          variant="text"
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 14, marginTop: 0 }}
                      >
                        <Skeleton
                          variant="text"
                          width={100}
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 10,
                          marginTop: 1,
                          marginBottom: 0,
                          color: "rgba(228, 230, 244, 0.6)",
                        }}
                      >
                        <Skeleton
                          variant="text"
                          width={100}
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Box sx={{ mx: 2, width: "100%" }}>
                      <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                        <Skeleton
                          variant="text"
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 14, marginTop: 0 }}
                      >
                        <Skeleton
                          variant="text"
                          width={100}
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 10,
                          marginTop: 1,
                          marginBottom: 0,
                          color: "rgba(228, 230, 244, 0.6)",
                        }}
                      >
                        <Skeleton
                          variant="text"
                          width={100}
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Box sx={{ mx: 2, width: "100%" }}>
                      <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                        <Skeleton
                          variant="text"
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 14, marginTop: 0 }}
                      >
                        <Skeleton
                          variant="text"
                          width={100}
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 10,
                          marginTop: 1,
                          marginBottom: 0,
                          color: "rgba(228, 230, 244, 0.6)",
                        }}
                      >
                        <Skeleton
                          variant="text"
                          width={100}
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Box sx={{ mx: 2, width: "100%" }}>
                      <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                        <Skeleton
                          variant="text"
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 14, marginTop: 0 }}
                      >
                        <Skeleton
                          variant="text"
                          width={100}
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 10,
                          marginTop: 1,
                          marginBottom: 0,
                          color: "rgba(228, 230, 244, 0.6)",
                        }}
                      >
                        <Skeleton
                          variant="text"
                          width={100}
                          sx={{ width: "100%" }}
                        ></Skeleton>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
        })}
    </Box>
  );
};

export default Skeletons1;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SendNotificationOption from "./sendNotificationOption";
import UsersOption from "./usersOption";
import { sendNotificationUser } from "../../../Reducer/sendNotification/sendNotificationSlice";
import ChecboxWithSpan from "../../Tools/CheckboxWithSpan";
import Button from "../../Tools/Button";
import SearchBar from "../../Tools/SearchBar";
import { all } from "axios";
import { Icon } from "@iconify/react/dist/iconify.js";
const NotificationFilter = ({ allFilter, setAllFilter }) => {
  const [active, setActive] = React.useState("dealer");
  const users = useSelector((state) => state.sendNotificationusers?.users);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(sendNotificationUser({ userType: active }));
  }, [dispatch, active]);

  const handleSearch = (data) => {
    dispatch(sendNotificationUser({ userType: active, name: data }));
  };
  const selectUnselectUser = (type, id) => {
    if (type) {
      setAllFilter({ ...allFilter, users: [...allFilter.users, id] });
    } else {
      setAllFilter({
        ...allFilter,
        users: allFilter.users.filter((v) => v !== id),
      });
    }
  };
  const getName = (item) => {
    if (item?.fullName && item?.fullName !== "N/A") return item?.fullName;
    else if (item?.dealerShipName && item?.dealerShipName !== "N/A")
      return item?.dealerShipName;
    else if (item?.email && item?.email !== "N/A") return item?.email;
    else if (item?.phoneNumber && item?.phoneNumber !== "N/A")
      return item?.phoneNumber;
    else return "--";
  };
  return (
    <div class="sideb">
      <div class="set">
        <h6>Send to</h6>
        <SendNotificationOption
          allFilter={allFilter}
          setAllFilter={setAllFilter}
        />
        <UsersOption allFilter={allFilter} setAllFilter={setAllFilter} />
      </div>

      <div class="set">
        <h5>Specific users</h5>
        <div class="switch-button " style={{ width: "unset" }}>
          <Button
            style={`switch-button-case ${active == "dealer" && "activeButton"}`}
            title={"Dealers"}
            onClick={() => setActive("dealer")}
          />
          <Button
            style={`switch-button-case ${active == "user" && "activeButton"}`}
            title={"Private users"}
            onClick={() => setActive("user")}
          />
        </div>
        <SearchBar
          placeholder={"Search Users"}
          event={{ onclick: handleSearch }}
        />
        {allFilter?.users?.length > 0 && (
          <h5 className="d-flex justify-content-between align-items-center">
            <span>
              {allFilter?.users?.length}{" "}
              {allFilter?.users?.length > 1 ? "users" : "user"} selected
            </span>
            <span title="Remove selection">
              <Icon
                role="button"
                onClick={(e) => setAllFilter({ ...allFilter, users: [] })}
                icon="mdi:bin-outline"
                fontSize={22}
                style={{ color: "#ff5c74" }}
              />
            </span>
          </h5>
        )}
        <ul
          className="thinScroll"
          style={{ height: "300px", overflowY: "scroll" }}
        >
          {users &&
            users?.map?.((item, index) => {
              return (
                <li
                  style={{ padding: "0px 15px 0px" }}
                  className="d-flex align-items-center"
                >
                  <ChecboxWithSpan
                    id={item?.id}
                    // parentStyle={"checkcontainer"}
                    childStyle={"radiobtn"}
                    inType={"checkbox"}
                    inputStyle={"quality"}
                    // disabled={allFilter.sendTo.length > 0}
                    name={`user[${index}]`}
                    value={item?._id}
                    title={`${getName(item)}`}
                    checked={allFilter.users.includes(item?._id)}
                    onChange={(e) =>
                      selectUnselectUser(e.target.checked, e.target.value)
                    }
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default NotificationFilter;

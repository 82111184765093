import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const SubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(!collapsed);
  const location = useLocation();
  const { icon, title, items } = props;

  return (
    <div>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed })}
      >
        <NavLink className="dropdown-toggle">
          <Icon
            icon={icon}
            fontSize={23}
            style={{ color: "#353131", margin: "0px 4px 3px 0px" }}
          />
          {title.toUpperCase()}
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-1": !collapsed })}
      >
        {items.map((item, index) => (
          <NavItem
            key={index}
            className="d-flex align-item-center"
            style={{ paddingInline: "25px" }}
          >
            <Icon
              icon={location.pathname === item.path ? item?.imageOne : item?.imageSecond}
              fontSize={23}
              style={{
                color: location.pathname === item.path ? "#437ee8" : "#353131",
                alignSelf: "center",
              }}
            />
            <NavLink
              tag={Link}
              to={item.path}
              style={{
                color: location.pathname === item.path ? "#437ee8" : "",
              }}
            >
              {item.title.toUpperCase()}
            </NavLink>
          </NavItem>
        ))}
      </Collapse>
    </div>
  );
};

export default SubMenu;

import React from "react";
const FormInput = ({ style, placeholder, intype, event, name, value }) => {
  return (
    <input
      class={style}
      value={value}
      type={intype}
      {...event(name)}
      placeholder={placeholder}
    />
  );
};
export default FormInput;

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function BoostrapModel({ style, show, heading, component, onHide, size, inlineStyle }) {
  return (
    <>
      <Modal
        show={show}
        size={size}
        onHide={onHide}
        className={
          style == "warning Edit_Section" ? "warning Edit_Section " : "warning "+style
        }
        style={inlineStyle}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{component}</Modal.Body>
      </Modal>
    </>
  );
}

export default BoostrapModel;

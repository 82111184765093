import React, { useEffect } from "react";
import MyprofileDetails from "./MyprofileDetails";
import Security from "./Security";
import { myprofile } from "../../../Reducer/profileSilce/profileSilce";
import { useDispatch, useSelector } from "react-redux";
document.title = "Profile";

export default function Profile() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.profile.users);
  useEffect(() => {
    dispatch(myprofile());
  }, [dispatch]);

  return (
    <div>
      <MyprofileDetails data={data || {}} />
      <Security data={data || {}} />
    </div>
  );
}

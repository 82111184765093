import { useEffect, useState } from "react";
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import SearchBar from "../../Tools/SearchBar";
import BasicTable from "../../Tools/Table";
import TradeBox from "../../Tools/TradeBox";
import { getInvoiceUrl, getStats, getUserTransactions } from "../../../services/api";
import { ITEM_PER_PAGE } from "../../../data/varible";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import { handleDownload } from "../../../data/utils";

const Transcation = ({ rows, columns, rowInformation, action, userId }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [status, setStatus] = useState("");
  const [rowInfo, setRowInfo] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const limit = ITEM_PER_PAGE;
  const getData = () => {
    setSubmitting(true);
    getUserTransactions({
      id: userId,
      page: currentPage,
      limit: limit,
      status: status,
    }).then((res) => {
      const formattedData = res?.data?.transactions?.map((v) => ({
        id: v?.invoiceNumber || "--",
        _id: v?._id,
        date: moment.unix(v?.createdAt).format("DD/MM/YYYY"),
        item: v?.transactionFor,
        amount: `$${v?.amount}`,
        status: v?.status,
      }));
      setData(formattedData);
      setTotalPages(res?.data?.totalPages);
      setSubmitting(false);
    });
    getStats({ userId: userId, type: "transaction" }).then((res) => {
      if (res?.status) {
        const rowInfoData = [
          {
            count:
              res?.data?.successPayments == 0
                ? "0"
                : res?.data?.successPayments,
            content: "Successful Transcation",
          },
          {
            count:
              res?.data?.failedPayments == 0 ? "0" : res?.data?.failedPayments,
            content: "Failed Transcation",
          },
        ];
        setRowInfo(rowInfoData);
      }
      setSubmitting(false);
    });
  };
  useEffect(() => {
    getData();
  }, [currentPage, status]);
  const options = [
    { name: "All", value: "" },
    { name: "Successful", value: "success" },
    { name: "Failed", value: "failed" },
  ];
  const downloadAction = {
    title: "Download Invoice",
    onclick: (data) => {
      getInvoiceUrl(data?._id).then((res) => {
        if (res?.status) {
          handleDownload(res?.data);
        }
      });
    },
    image: "Download.png",
    label: (
      <Icon
        icon="material-symbols:download"
        fontSize={30}
        style={{ color: "#969696" }}
      />
    ),
  };
  return (
    <div class="set_list2">
      <h3 class="thr">
        Transactions
        <ul>
          {/* <li>
            <SearchBar />
          </li> */}
          <li>
            <select
              class="form-control"
              onChange={(e) => setStatus(e.target.value)}
            >
              {options.map((v, i) => {
                return (
                  <option key={i} value={v?.value}>
                    {v?.name}
                  </option>
                );
              })}
            </select>
          </li>
          <li>
            <HeadingWithDatePicker image={"../assets/images/calendar.png"} />
          </li>
        </ul>
      </h3>
      <BasicTable
        rows={data || []}
        columns={columns}
        rowInformation={rowInfo}
        actionType={"dropdown"}
        // actions={action}
        customAction={downloadAction}
        dropDownImage={"../assets/images/dash_img/threedot.png"}
        itemperpage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        submitting={submitting}
      />
    </div>
  );
};

export default Transcation;

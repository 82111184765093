import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DashboardPage, ApprovalQue, SendNotification, PagesPage, BannerImages, BlockWords, AccountDetails, SinglePendingApprovalUser, EditPage, UserAccount, Pricing, Profile, Login } from '../Components';
import Sidebar from '../Components/Layouts/Sidebar';
import EditProfile from '../Components/Pages/Profile/EditProfile';
import EditSecurity from '../Components/Pages/Profile/EditSecurity';
import EditAdvert from '../Components/Pages/EditAdvert';
import AdvertApproval from '../Components/Pages/AdvertApproval';
import ViewAdvert from '../Components/Pages/ViewAdvert';
import Colors from '../Components/Pages/Preferences/colors';
import Features from '../Components/Pages/Preferences/features';
import Makes from '../Components/Pages/Preferences/makes';
import ViewMake from '../Components/Pages/ViewMake';
import MakeYears from '../Components/Pages/Preferences/makeYears';
import Adverts from '../Components/Pages/Adverts';


const RouteWebPage = () => { 
    return (
        <Routes>
            <Route exact path="/login" element={<Login/>}/>
            <Route exact path='/' element={<Sidebar component={<DashboardPage />}/>} />
            <Route exact path='/approval_que' element={<Sidebar component={<ApprovalQue />}/>} />
            <Route exact path='/ad_approval_que' element={<Sidebar component={<AdvertApproval />}/>} />
            <Route exact path='/adverts' element={<Sidebar component={<Adverts />}/>} />

            <Route exact path='/pages' element={<Sidebar component={<PagesPage />}/>} />
            <Route exact path='/block_words' element={<Sidebar component={<BlockWords />}/>} />
            <Route exact path='/reveiw_approval_que' element={<Sidebar component={<SinglePendingApprovalUser/>}/>} />

            <Route exact path='/edit_page/:id' element={<Sidebar component={<EditPage />}/>} />
            <Route exact path='/user_accounts' element={<Sidebar component={<UserAccount />}/>} />
            <Route exact path='/account_details' element={<Sidebar component={<AccountDetails />}/>} />
            <Route exact path='/view_advert' element={<Sidebar component={<ViewAdvert />}/>} />
            <Route exact path='/edit_advert' element={<Sidebar component={<EditAdvert />}/>} />
            <Route exact path='/makes' element={<Sidebar component={<Makes />}/>} />
            <Route exact path='/view_make' element={<Sidebar component={<ViewMake />}/>} />
            <Route exact path='/colors' element={<Sidebar component={<Colors />}/>} />
            <Route exact path='/features' element={<Sidebar component={<Features />}/>} />
            <Route exact path='/makeYears' element={<Sidebar component={<MakeYears />}/>} />

            <Route exact path='/send_notifications' element={<Sidebar component={<SendNotification />}/>} />
            <Route exact path='/send_email' element={<Sidebar component={<h3>Page Not Found</h3>}/>} />
            <Route exact path='/pricing_controls' element={<Sidebar component={<Pricing />}/>} />

            <Route exact path='/banner_images' element={<Sidebar component={<BannerImages />}/>} />
            <Route exact path='/profile' element={<Sidebar component={<Profile />}/>} />
            <Route exact path='/EditProfile' element={<Sidebar component={<EditProfile />}/>} />
            <Route exact path='/EditSecurity' element={<Sidebar component={<EditSecurity />}/>} />
        </Routes>
    )
}

export default RouteWebPage;
import Button from "../Button";
const SubscriptionBox = ({
  parentSTyle,
  childStyle,
  title,
  time,
  pricing,
  benefits,
  onClick,
  id,
  style,
  description,
  duration,
  item,
  type,
}) => {
  // if (!Array.isArray(benefits)) {
  //   // Handle the case where data is not an array
  //   console.error("data is not an array");
  //   return null; // or some other error handling logic
  // }
  const formatDuration = (val) => {
    if (val == -1) return "Until sold";
    return val / 7 + " weeks";
  };
  console.log(type);
  return (
    <div class={parentSTyle}>
      <div class={childStyle}>
        <div class={`hd ${title == "Premium" && "pr"}`} style={style}>
          <h4>{title}</h4>
          <p
            style={{
              height: type === "checkMyCar" ? "" : "120px",
              fontWeight: 600,
            }}
          >
            {description}
          </p>
          <h5>{time}</h5>
        </div>
        <h4>${pricing}</h4>
        {type === "checkMyCar" ? null : (
          <p className="fw-semibold">
            Plan duration: {formatDuration(duration)}
          </p>
        )}
        <ul>
          {benefits &&
            benefits.length > 0 &&
            benefits.map((item) => {
              return <li style={{ fontSize: "15px" }}>{item}</li>;
            })}
        </ul>
        <Button
          style={"edit_plan"}
          title={"Edit Plan"}
          onClick={() => onClick(id)}
        />
      </div>
    </div>
  );
};

export default SubscriptionBox;

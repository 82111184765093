import React from "react";
import { useNavigate } from "react-router";
export default function MyprofileDetails({ data }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/EditProfile", { state: { data: data } });
  };
  return (
    <div className="overview">
      <h3 class="pt-0 d-flex justify-content-between">
        Basic details
        <div role="button" onClick={handleClick}>
          <img
            src="../assets/images/pencil.png"
            height={18}
            className="mx-2"
            alt="pencil"
          />
          <span style={{ fontSize: "18px" }}>Edit Profile</span>
        </div>
      </h3>
      <div class="my_profile">
        <div class="row">
          <div class="col-sm-3 col-xl-2">
            <div class="imgs">
              <img
                src={
                  data?.profilePicture && data?.profilePicture !== "N/A"
                    ? data?.profilePicture
                    : "/assets/images/ap2.png"
                }
                //  height={50}
                //  width={50}
                className="border rounded-2"
                style={{ objectFit: "contain", width: "" }}
                align="img"
              />
            </div>
          </div>
          <div class="col-sm-9 col-xl-10">
            <div class="row">
              <div class="col-md-4 ">
                <h6>Name</h6>
                <p>{data.fullName}</p>
              </div>
              <div class="col-md-4">
                <h6>Email</h6>
                <p>{data.email} </p>
              </div>
              {data.phoneNumber && data.phoneNumber !== "N/A" && (
                <div class="col-md-4">
                  <h6>Phone</h6>
                  <p>+{data.phoneNumber}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Button from "../Button";
import FormInput from "../FormInput";

//form imports
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import TextareaWithAttatch from "../../Pages/SendNotification/TextareaWithAttatch";
import { Input } from "reactstrap";
import { error } from "jquery";
import { Icon } from "@iconify/react/dist/iconify.js";

const ReasonModel = ({
  buttonTitle,
  label,
  option,
  type,
  handleSubmitEvent,
  id,
  onHide,
  meta,
  data,
  setData,
  selectedFile,
  setSelectedFile,
  loading,
  loadingWithoutMail,
  continueWithoutCallback,
}) => {
  const schema = Yup.object().shape({
    title: ["warning", "toggleAd"].includes(data?.modalType) || data?.modalType === 'delist' && !data?.allowedToLogin
      ? Yup.string().required("This field is required")
      : Yup.string(),
    body: ["warning", "delist", "toggleAd"].includes(data?.modalType)
      ? Yup.string().required("This field is required")
      : Yup.string(),
    reason:
      (data?.modalType === "delist" && data?.allowedToLogin)
        ? Yup.string().required("This field is required")
        : Yup.string(),
    note: Yup.string(),
  });
  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  
  const handleAddNote = (val) => {
    if (!val) return;
    setData({ ...data, notes: [...data?.notes, val] });
    setValue("note", "");
    console.log(val);
  };
  const handleDeleteNote = (index) => {
    setData({ ...data, notes: data?.notes.filter((v, i) => i !== index) });
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitEvent)}>
      {data?.modalType === "addNote" ? (
        ""
      ) : (
        <label>{`Reason for ${label}?`}</label>
      )}
      {/* <FormInput intype={"hidden"} value={id} name={"id"} /> */}
      {(["warning", "toggleAd"].includes(data?.modalType) || data?.modalType === 'delist' && !data?.allowedToLogin) && (
        <>
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Subject"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onChange}
              />
            )}
          />
          {errors.title && <p className="helperText">{errors.title.message}</p>}
          <br />
        </>
      )}
      {(data?.modalType === "delist" && data?.allowedToLogin) &&  (
        <>
          <select class="form-control" name="reason" {...register("reason")}>
            {option.map((item) => {
              return <option value={item?.name}>{item?.name}</option>;
            })}
          </select>
          {errors.reason && (
            <p className="helperText">{errors.reason.message}</p>
          )}
          <br />
        </>
      )}
      {["warning", "delist", "toggleAd"].includes(data?.modalType) && (
        <TextareaWithAttatch
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          control={control}
          name={"body"}
          errors={errors}
          textareaPlaceholder={"Write your email body..."}
          showAttatch={true}
          textareaHeight={"300px"}
          attatchStyles={{ top: "260px", left: "12px", position: "absolute" }}
        />
      )}
      {data?.modalType === "addNote" && (
        <>
          <ul style={{ listStyleType: "none", padding: "0px 0px 0px 0px" }}>
            {data?.notes?.map((v, i) => {
              return (
                <li
                  className="d-flex justify-content-between p-2 rounded border mb-1"
                  key={i}
                >
                  <span>{v}</span>
                  <span>
                    <Icon
                      onClick={() => handleDeleteNote(i)}
                      icon="mdi:delete"
                      fontSize={20}
                      style={{ color: "#ff6b6b", cursor: "pointer" }}
                    />
                  </span>
                </li>
              );
            })}
          </ul>
          <div className="">
            <div className="col-12">
              <Controller
                name={"note"}
                control={control}
                render={({ field }) => (
                  <Input
                    type="textarea"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    className="w-100 h-100"
                    style={{ resize: "none", marginTop: "0px" }}
                    placeholder="Type something..."
                  />
                )}
              />
            </div>
            <div className="d-flex justify-content-end">
              <div className="col-2 mt-2 me-2">
                <button
                  type="button"
                  className="themeButtonYes h-100 w-100"
                  onClick={() => handleAddNote(getValues("note"))}
                >
                  <Icon
                    icon="ic:round-add"
                    fontSize={30}
                    style={{ color: "white" }}
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div class="mt-5 d-flex justify-content-end">
        <Button
          type={"button"}
          style={"btn btn-secondary w-25"}
          title={"Close"}
          onClick={onHide}
        />
        <Button
          type={"submit"}
          style={"btn btn-primary w-100"}
          disabled={loading}
          title={
            loading ? (
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              buttonTitle
            )
          }
        />
        {["delist"].includes(data.modalType) && (
          <Button
            type={"button"}
            onClick={continueWithoutCallback}
            inlineStyle={{ width: "100%" }}
            style={"btn btn-primary"}
            disabled={loading}
            title={
              loadingWithoutMail ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Continue Without Mail"
              )
            }
          />
        )}
      </div>
    </form>
  );
};
export default ReasonModel;
